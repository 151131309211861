import { useState } from "react";
import styles from "../../css/dist/index.module.css";
import { Animated } from "react-animated-css";

export const ErrorNotif = ({ errorText, onClose, className }: { errorText: string | undefined, onClose: () => void, className?: string }) => {
  const ANIMATION_DURATION_MS = 1000; const _ADMS = ANIMATION_DURATION_MS;

  const [enableAnimation, setEnableAnimation] = useState(false);
  setTimeout(() => {
    setEnableAnimation(true);
  }, _ADMS);

  return (<Animated animationIn="fadeInUp" animationOut="fadeOutDown" isVisible={Boolean(errorText)} className={`${styles["fixed"]} ${styles["left-5"]} ${styles["right-5"]} ${styles["bottom-5"]} ${className} ${styles["z-10"]}`} animationInDuration={_ADMS} animationOutDuration={_ADMS} animationOutDelay={enableAnimation ? 0 : -_ADMS}>
    <div role="alert" className={`${styles["alert"]} ${styles["alert-error"]} ${styles["flex"]} ${styles["align-middle"]} ${styles["justify-between"]}`}>
      <span className={`${styles["ml-3"]}`}>{errorText}</span>
      <div>
        <button className={`${styles.btn} ${styles["btn-sm"]} ${styles["btn-circle"]} ${styles["btn-error"]} ${styles["border-none"]} ${styles["font-bold"]}}`} onClick={onClose}>✕</button>
      </div>
    </div>
  </Animated>);
};
