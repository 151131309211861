export type Transition = "maskRL" | "maskLR" | "maskBT" | "maskTB" | "circCO" | "circOC" | "instant";

export type TransitionPackages = "linear_mask_transition" | "";

export const transitionToPackage = {
  maskRL: "linear_mask_transition",
  maskLR: "linear_mask_transition",
  maskBT: "linear_mask_transition",
  maskTB: "linear_mask_transition",
  circCO: "",
  circOC: "",
  instant: "",
};

export type SingleJSXElement = JSX.Element | string | null | undefined;
export type MultipleJSXElements = (SingleJSXElement)[]
export type JSXElements = SingleJSXElement | MultipleJSXElements;

export type Theme = "light" | "dark";

export type TransitionState = string;
export type TransitionStateFunction = React.TransitionStartFunction;

export type OnEventSetter<T> = (newValue: T) => void;

export type ReactSetter<T> = React.Dispatch<React.SetStateAction<T>>
export type ReactTransitionStarter = React.TransitionStartFunction;

