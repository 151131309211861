import styles from "../../css/dist/index.module.css";

export const ArticleFooter = () => {
  return (
    <footer className={`${styles["footer"]} ${styles["bg-neutral"]} ${styles["text-neutral-content"]} ${styles["items-center"]} ${styles["py-4"]} ${styles["md:px-8"]} ${styles["px-2"]}`}>
      <aside className={`${styles["grid-flow-col"]} ${styles["items-center"]} ${styles["justify-center"]} ${styles["md:justify-start"]}`}>
        <img src={`${process.env.PUBLIC_URL}/icon.png`} alt="badavas" className={`${styles["w-12"]}`}></img>
        <p>Copyright © {new Date().getFullYear()} - All rights reserved</p>
      </aside>
      <nav className={`${styles["grid-flow-col"]} ${styles["hidden"]} ${styles["md:block"]} ${styles["gap-4"]} ${styles["md:place-self-center"]} ${styles["md:justify-self-end"]}`}>
        {/* YOUTUBE LOGO */}
        <a href="https://youtu.be/xhnnK_3BKi0?si=lCoXhEvn-3qqkTHd">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            className={`${styles["fill-current"]}`}>
            <path
              d="M19.615 3.184c-3.604-.246-11.631-.245-15.23 0-3.897.266-4.356 2.62-4.385 8.816.029 6.185.484 8.549 4.385 8.816 3.6.245 11.626.246 15.23 0 3.897-.266 4.356-2.62 4.385-8.816-.029-6.185-.484-8.549-4.385-8.816zm-10.615 12.816v-8l8 3.993-8 4.007z"></path>
          </svg>
        </a>
      </nav>
    </footer>
  );
};
