import { User } from "../../../../lib";
import { OnEventSetter, ReactTransitionStarter, TransitionState } from "../../types";
import styles from "../../css/dist/index.module.css";
import { VerticalStepSequence } from "../util/stepsequence";
import { useCallback, useEffect, useReducer, useRef, useState } from "react";
import { Animated } from "react-animated-css";
import { ErrorNotif } from "../util/errornotif";
import { getPitchCount } from "../../connections";

export const Upload = ({ currUser, startTransition, setTransitionState, setPath, path }: { currUser: User, startTransition: ReactTransitionStarter, setTransitionState: OnEventSetter<TransitionState>, setPath: OnEventSetter<string>, path: string }) => {
  const [stepCompletionStates, updateStepCompletionStates] = useReducer((state: boolean[], action: { type: "INITIALIZE", stepCount: number } | { type: "UPDATE", stepIX: number, newValue: boolean }) => {
    switch (action.type) {
      case "INITIALIZE":
        const arr: boolean[] = new Array(action.stepCount).fill(false);
        arr[0] = true;
        return arr;
      case "UPDATE":
        state[action.stepIX] = action.newValue;
        return state;
    }
  }, []);


  useEffect(() => {
    updateStepCompletionStates({ type: "INITIALIZE", stepCount: 3 });
  }, []);


  const [loadingVidURL, ] = useState(require("../../utility/loading.mp4"));


  const [vidURL, setVidURL] = useState("");
  useEffect(() => {
    updateStepCompletionStates({ type: "UPDATE", stepIX: 1, newValue: Boolean(vidURL) });
  }, [vidURL]);


  const ANIM_TIME_MS = 1000;


  // STEP 1 AND 2, FILE UPLOAD AND UPDATE PREVIEW
  const [errorText, setErrorText] = useState<string | undefined>();
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [previewIsVisible, setPreviewIsVisible] = useState(true);

  const failUpload = useCallback(() => {
    updateStepCompletionStates({ type: "UPDATE", stepIX: 1, newValue: false });
    updateStepCompletionStates({ type: "UPDATE", stepIX: 2, newValue: false });

    setPreviewIsVisible(false);

    setTimeout(() => {
      setPreviewIsVisible(true);
      setVidURL("");
    }, ANIM_TIME_MS);
  }, []);


  const MAX_PITCHES_PER_USER = 3; const _MPPU = MAX_PITCHES_PER_USER;
  const MAX_PITCH_STORAGE_SIZE_MB = 50; const _MPSSMB = MAX_PITCH_STORAGE_SIZE_MB;
  const VIDEO_MAX_DUR_S = 20;

  const onFileInputChange = useCallback(async () => {
    const userPitchCount = await getPitchCount(currUser.uid);

    if (currUser.verified === 0) {
      failUpload();
      setErrorText(
        `Verification level 1 is necessary to upload a pitch. To achieve verification level 1, confirm your email.`
      );
      return;
    }

    if (userPitchCount >= _MPPU) {
      failUpload();
      setErrorText(`You are currently storing the maximum number of pitches (${_MPPU}). Storage space is costly for us, so please delete an existing pitch before uploading this one, thanks.`);
      return;
    }

    if (!fileInputRef.current?.files) {
      failUpload();
      setErrorText("Something went wrong... Please try again.");
      return;
    }

    const file = fileInputRef.current.files[0];
    
    if (file.size > _MPSSMB * 1024 * 1024) {
      failUpload();
      setErrorText(`Max video size is ${_MPSSMB} MB, recieved ${(file.size / 1024 / 1024).toFixed(1)} MB.`);
      return;
    }

    const video = document.createElement("video");
    video.src = URL.createObjectURL(file);

    video.addEventListener("loadedmetadata", () => {
      if (video.duration > VIDEO_MAX_DUR_S) {
        failUpload();
        setErrorText(`Max video time is ${VIDEO_MAX_DUR_S}s. Recieved video of ${VIDEO_MAX_DUR_S.toFixed(1)}s.`);
        return;
      }
      
      setPreviewIsVisible(false);

      updateStepCompletionStates({ type: "UPDATE", stepIX: 1, newValue: true });
      updateStepCompletionStates({ type: "UPDATE", stepIX: 2, newValue: true });
      
      setTimeout(() => {
        setPreviewIsVisible(true);
        setVidURL(video.src);
      }, ANIM_TIME_MS);
    });
  }, [failUpload, currUser.uid, _MPPU, _MPSSMB, currUser.verified]);


  // STEP 3, PITCH SHARE
  const [attemptingUpload, setAttemptingUpload] = useState(false);
  const onPitchUploadAttempt = useCallback((evt: React.MouseEvent) => {
    if (attemptingUpload || !stepCompletionStates[0] || !stepCompletionStates[1]) {
      evt.preventDefault();
      return;
    }

    setAttemptingUpload(true);
  }, [attemptingUpload, stepCompletionStates]);
  
  return (<div className={`${styles["p-4"]} ${styles["w-full"]} ${styles["max-w-3xl"]} ${styles["mx-auto"]}`}>
    <form
      action="/api/upload-pitch"
      encType="multipart/form-data"
      method="post"
      className={`${styles["w-full"]}`}
    >
      <VerticalStepSequence className={`${styles["w-full"]} ${styles["pr-4"]}`} stepCompletionStates={stepCompletionStates}>

        <div className={`${styles["flex-col"]} ${styles["flex"]} ${styles["w-full"]}`}>
          <h2 className={`${styles["font-bold"]} ${styles["text-left"]} ${styles["text-xl"]} ${styles["md:text-2xl"]}`}>Pitch Upload</h2>
          <p className={`${styles["text-left"]} ${styles["text-sm"]} ${styles["md:text-base"]} ${styles["mb-4"]}`}>
            Recommended resolution 1920 pixels high, 1080 pixels wide. Maximum file size: {MAX_PITCH_STORAGE_SIZE_MB}MB. Maximum duration: {VIDEO_MAX_DUR_S}s.
          </p>
          <input ref={fileInputRef} type="file" id="pitch-input" name="pitch-input" className={`${styles["file-input"]} ${styles["w-full"]} ${styles["max-w-xs"]}`} accept="video/mp4,video/quicktime" onChange={onFileInputChange} />
        </div>

        <div className={`${styles["flex-col"]} ${styles["flex"]} ${styles["w-full"]}`}>
          <h2 className={`${styles["font-bold"]} ${styles["text-left"]} ${styles["text-xl"]} ${styles["md:text-2xl"]} ${styles["mb-4"]} ${styles["md:mb-6"]}`}>Preview</h2>
          <Animated animationIn="fadeInUp" animationOut="fadeOutDown" animationInDuration={ANIM_TIME_MS} animationOutDuration={ANIM_TIME_MS} isVisible={previewIsVisible}>
            <video playsInline webkit-playsinline className={`${styles["w-5/6"]} ${styles["drop-shadow-accent"]} ${styles["max-w-56"]} ${styles["rounded-xl"]}`} style={{ aspectRatio: "9 / 16" }} src={vidURL === "" ? loadingVidURL : vidURL} autoPlay muted loop controls={Boolean(vidURL)}></video>
          </Animated>
        </div>

        <div className={`${styles["w-full"]}`}>
          <button className={`${styles.btn} ${styles["btn-accent"]} ${styles["drop-shadow-accent"]} ${styles["w-full"]} ${stepCompletionStates[0] && stepCompletionStates[1] ? "" : `${styles["btn-disabled"]}`}`} onClick={onPitchUploadAttempt}>Share Pitch 🎥</button>
        </div>
        
      </VerticalStepSequence>
    </form>
    <ErrorNotif errorText={errorText} onClose={() => { setErrorText(undefined); }}></ErrorNotif>
  </div>);
};
