import { CSSProperties } from "react";
import styles from "../../css/dist/index.module.css";
import { MultipleJSXElements } from "../../types";

/**
 * step completion states input must be equal in length to children count
 * also not all classes are compatible brrrrrrrrrrr (and less classes than most components brrrrrrrrrrrrrrrrr)
 */
export const VerticalStepSequence = ({ children: steps, stepCompletionStates, className, style }: { children: MultipleJSXElements, stepCompletionStates: boolean[], className?: string, style?: CSSProperties }) => {
  return (<ul className={`${styles.steps} ${styles["steps-vertical"]} ${styles["w-full"]} ${className}`} style={style}>
    {
      steps.map((step, ix) => {
        return (<li className={`${styles.step} ${styles["w-full"]} ${styles["flex"]} ${stepCompletionStates[ix] ? styles["step-primary"] : ""} ${styles["before:-translate-y-1/2"]}`} key={ix}>
          <div className={`${styles["w-full"]} ${styles["pl-5"]}`}>
            { step }
          </div>
        </li>);
      })
    }
  </ul>);
};

