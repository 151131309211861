import { useCallback, useEffect, useReducer, useRef, useState } from "react";
import { User } from "../../../../lib";
import styles from "../../css/dist/index.module.css";
import { OnEventSetter, ReactTransitionStarter, TransitionState } from "../../types";
import { deletePost, getLastPostTime, getPostCount, getRootPosts, PostData, postPost } from "../../connections";
import { MiniProfile } from "../util/profileparts";
import DOMPurify from "dompurify";
import { Modal } from "../util/modal";
import { marked } from "marked";
import { ErrorNotif } from "../util/errornotif";
import { Link } from "../util/link";
import { LoadingIcon } from "../util/loadingicon";


const mdtoshtml = (markdown: string) => {
  return DOMPurify.sanitize(marked(markdown) as string);
};

export const Posts = ({ currUser, startTransition, setTransitionState, setPath, path }: { currUser: User, startTransition: ReactTransitionStarter, setTransitionState: OnEventSetter<TransitionState>, setPath: OnEventSetter<string>, path: string }) => {
  const [otherUserUID, setOtherUserUID] = useState<string | undefined>();
  useEffect(() => {
    const pathSplit = path.split("/");
    if (pathSplit.length !== 3) {
      setOtherUserUID(undefined);
      return;
    }
    setOtherUserUID(pathSplit[2]);
  }, [path]);


  const POST_LOAD_LIMIT_INC = 10; const _PLLI = POST_LOAD_LIMIT_INC;
  const [postLoadLimit, incrementPostLoadLimit] = useReducer((state: number) => {
    return state + _PLLI;
  }, _PLLI);


  const [postDataObjs, setPostDataObjs] = useState<PostData[]>([]);
  const [postCount, setPostCount] = useState(-1);
  useEffect(() => {
    setPostDataObjs([]);
    setPostCount(-1);

    Promise.all([
      getRootPosts({ targetUID: otherUserUID, limit: postLoadLimit }),
      getPostCount(otherUserUID)
    ]).then(([posts, cnt]) => {
      setPostDataObjs(posts ?? []);
      setPostCount(cnt ?? 0);
    });
  }, [postLoadLimit, otherUserUID]);


  const [errorText, setErrorText] = useState<string | undefined>();


  const postCreationContentTextareaRef = useRef<HTMLTextAreaElement>(null);
  const postPreviewContainerRef = useRef<HTMLDivElement>(null);
  const postCreationTitleInputRef = useRef<HTMLInputElement>(null);
  const postPreviewTitleRef = useRef<HTMLHeadingElement>(null);
  const onPreviewBegin = useCallback(() => {
    if (!postCreationContentTextareaRef.current || !postPreviewContainerRef.current || !postPreviewTitleRef.current || !postCreationTitleInputRef.current) {
      setErrorText("An unexpected error occurred. Please try again.");
      return;
    }

    postPreviewTitleRef.current.innerHTML = postCreationTitleInputRef.current.value ?? "(Untitled)";
    postPreviewContainerRef.current.innerHTML = mdtoshtml(postCreationContentTextareaRef.current.value);
  }, []);




  
  const MIN_TIME_BETWEEN_POSTS_MS = 30 * 60 * 1000;
  const [lastPostTime, setLastPostTime] = useState(Number.POSITIVE_INFINITY);
  useEffect(() => {
    getLastPostTime(currUser.uid).then((currUserLastPostTime) => {
      if (!currUserLastPostTime) {
        setLastPostTime(Number.NEGATIVE_INFINITY);
        return;
      }
      setLastPostTime(currUserLastPostTime);
    });
  }, [currUser.uid]);





  const MAX_POST_CONTENT_HTML_CHARS = 65535; const _MPCHC = MAX_POST_CONTENT_HTML_CHARS;
  const MAX_POST_TITLE_CHARS = 256; const _MPTC = MAX_POST_TITLE_CHARS;





  const [attemptingPostShare, setAttemptingPostShare] = useState(false);

  const failAttempt = useCallback(() => {
    setAttemptingPostShare(true);
  }, []);

  const sharePost = useCallback(() => {
    if (!currUser.verified) {
      failAttempt();
      setErrorText("You must be verification level 1 to post. Verify your email.");
      return;
    }

    const contentContObj = postCreationContentTextareaRef.current, titleContObj = postCreationTitleInputRef.current;
    
    if (!contentContObj || !titleContObj || attemptingPostShare) {
      failAttempt();
      setErrorText("Something unexpected happened... please try again.");
      return;
    }

    const contentHTML = mdtoshtml(contentContObj.value);
    const titleValue = titleContObj.value.length ? titleContObj.value : "(Untitled)";
    if (!contentHTML.length) {
      failAttempt();
      setErrorText("Please write something in your post.");
      return;
    }

    if (contentHTML.length > _MPCHC) {
      failAttempt();
      setErrorText(`Please shorten your post. We convert your post to HTML; currently your post represents ${contentHTML.length} HTML characters. Maximum length is ${_MPCHC} HTML characters.`);
      return;
    }

    if (titleValue.length > _MPTC) {
      failAttempt();
      setErrorText(`Please shorten your title. Max allowed length: ${_MPTC}, current length: ${titleValue.length}. (why would you ever need so many characters for a title??)`);
      return;
    }

    postPost(contentHTML, titleValue).then((success) => {
      if (!success) {
        setErrorText("An unexpected error occurred. Please try again.");
        return;
      }
      document.getElementById("post-creation-modal-close")?.click();
      contentContObj.value = "";
      titleContObj.value = "";
      window.location.reload();
    });
  }, [attemptingPostShare, _MPCHC, _MPTC, failAttempt, currUser.verified]);

  
  return (<div className={`${styles["max-w-4xl"]} ${styles["w-full"]} ${styles["bg-base-100"]} ${styles["mx-auto"]}`}>
    <div className={`${styles["mx-auto"]} ${styles["w-full"]} ${styles["h-full"]} ${styles["text-secondary"]}`}>
      <div className={`${styles["text-neutral-content"]}`}>
        <div className={`${styles["flex"]} ${styles["flex-col"]} ${styles["gap-4"]} ${styles["relative"]} ${styles["p-5"]}`}>
          {
            (!otherUserUID)
            ? (
              Date.now() - lastPostTime >= MIN_TIME_BETWEEN_POSTS_MS
              ? (<label htmlFor="post-creation-modal" className={`${styles["w-full"]} ${styles["btn"]} ${styles["btn-accent"]} ${styles["drop-shadow-accent"]} ${styles["text-lg"]} ${styles["md:text-xl"]} ${styles["h-16"]} ${styles["md:h-20"]}`}>Share a post</label>)
              : (<label className={`${styles["w-full"]} ${styles["btn"]} ${styles["btn-accent"]} ${styles["btn-disabled"]} ${styles["drop-shadow-accent"]} ${styles["text-md"]} ${styles["md:text-lg"]} ${styles["h-16"]} ${styles["md:h-20"]}`}>Please wait {((MIN_TIME_BETWEEN_POSTS_MS - (Date.now() - lastPostTime)) / 1000 / 60).toFixed(0)} minutes to post again.</label>)
            )
            : (<>
                <Link startTransition={startTransition} redirectPath={"/posts"} setTransitionState={setTransitionState} setPath={setPath} path={path} transition={"maskRL"} className={`${styles["w-full"]} ${styles["bg-accent"]} ${styles["text-accent-content"]} ${styles["rounded-lg"]} ${styles["shadow-md"]} ${styles["p-5"]} ${styles["flex"]} ${styles["flex-col"]} ${styles["items-center"]}`}>
                  <span className={`${styles["text-2xl"]} ${styles["font-extrabold"]} ${styles["text-center"]}`}>{currUser.uid === otherUserUID ? "Your" : `@${otherUserUID}'s`} Posts</span>
                  <span className={`${styles["underline"]} ${styles["opacity-70"]}`}>View All Posts</span>
                </Link>
              </>)
          }
          {
            postDataObjs.map((postDataObj, ix) => {
              return (<Post currUser={currUser} postDataObj={postDataObj} startTransition={startTransition} setTransitionState={setTransitionState} setPath={setPath} path={path} key={ix} onError={(error) => { setErrorText(error); }}></Post>);
            })
          }
          {
            postLoadLimit < postCount
            ? <button className={`${styles["btn"]} ${styles["btn-secondary"]}`} onClick={incrementPostLoadLimit}>Load More</button>
            : null
          }
          {
            postCount === 0
            ? <p className={`${styles["font-lg"]} ${styles["mx-auto"]} ${styles["mt-4"]}`}>No Posts</p>
            : null
          }
          {
            postCount === -1
            ? <div className={`${styles["mx-auto"]} ${styles["mt-2"]}`}><LoadingIcon></LoadingIcon></div>
            : null
          }
          <Modal id="post-creation-modal">
            <div className={`${styles["flex"]} ${styles["flex-col"]} ${styles["items-center"]} ${styles["gap-4"]} ${styles["pb-6"]}`}>
              <h1 className={`${styles["font-bold"]} ${styles["md:text-xl"]}`}>Share Post</h1>
              <input ref={postCreationTitleInputRef} type="text" className={`${styles["input"]} ${styles["w-full"]} ${styles["text-lg"]} ${styles["min-h-8"]}`} placeholder="Title"></input>
              <textarea className={`${styles["textarea"]} ${styles["w-full"]} ${styles["min-h-56"]}`} ref={postCreationContentTextareaRef} placeholder="Type your post here :>"></textarea>
              {/* MARKDOWN PREVIEW */}
              <label htmlFor="post-creation-preview-modal" className={`${styles["w-full"]} ${styles["btn"]} ${styles["btn-primary"]}`} onClick={onPreviewBegin}>Preview</label>
              <button className={`${styles["btn"]} ${styles["btn-accent"]} ${styles["w-full"]} ${styles["drop-shadow-accent"]} ${attemptingPostShare ? styles["btn-disabled"] : ""}`} onClick={() => {
                setAttemptingPostShare(true);
                sharePost();
              }}>SHARE!</button>
            </div>
          </Modal>
          <Modal id="post-creation-preview-modal">
            <div className={`${styles["flex"]} ${styles["flex-col"]} ${styles["items-center"]} ${styles["gap-4"]}`}>
              <div className={`${styles["flex"]} ${styles["flex-col"]} ${styles["w-full"]} ${styles["items-center"]}`}>
                {/* eslint-disable-next-line */}
                <h1 ref={postPreviewTitleRef} className={`${styles["font-bold"]} ${styles["text-2xl"]}`}></h1>
                <h2 className={`${styles["font-medium"]} ${styles["text-md"]} ${styles["italic"]}`}>Post Preview</h2>
              </div>
              <div ref={postPreviewContainerRef} className={`${styles["text-left"]} ${styles["w-full"]} ${styles["r-norm-list"]} ${styles["px-4"]}`}></div>
              <MiniProfile user={currUser} startTransition={startTransition} setTransitionState={setTransitionState} setPath={setPath} path={path}></MiniProfile>
            </div>
          </Modal>
        </div>
      </div>
    </div>

    <ErrorNotif errorText={errorText} onClose={() => { setErrorText(undefined); }}></ErrorNotif>
  </div>);
};

const Post = ({ currUser, postDataObj, startTransition, setTransitionState, setPath, path, onError }: { currUser: User, postDataObj: PostData, startTransition: ReactTransitionStarter, setTransitionState: OnEventSetter<TransitionState>, setPath: OnEventSetter<string>, path: string, onError: (error: string) => void }) => {
  const postContentDivRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    // INJECT POST CONTENT
    if (!postContentDivRef.current) return;
    postContentDivRef.current.innerHTML = DOMPurify.sanitize(postDataObj.value.replaceAll(`\\"`, `"`));
  }, [postDataObj.value]);

  const [loadPFP, setLoadPFP] = useState(false);
  const [readModalID, setReadModalID] = useState("");
  const [deleteModalID, setDeleteModalID] = useState("");

  useEffect(() => {
    setDeleteModalID(`post-${postDataObj.postid}-delte-modal`);
    setReadModalID(`post-${postDataObj.postid}-content-modal`);
  }, [postDataObj.postid]);

  const [attemptingPostDeletion, setAttemptingPostDeletion] = useState(false);
  const attemptDeletePost = useCallback(async () => {
    if (attemptingPostDeletion) return;

    setAttemptingPostDeletion(true);

    const success = await deletePost(postDataObj.postid);

    if (!success) {
      onError("Something unexpected occurred... Please try again.");
      return;
    }

    window.location.reload();
  }, [attemptingPostDeletion, onError, postDataObj.postid]);

  return (<div className={`${styles["w-full"]} ${styles["bg-neutral"]} ${styles["text-neutral-content"]} ${styles["rounded-lg"]} ${styles["shadow-md"]} ${styles["p-5"]} ${styles["break-words"]}`}>
    <h1 className={`${styles["font-bold"]} ${styles["text-2xl"]} ${styles["md:text-4xl"]} ${styles["overflow-ellipsis"]} ${styles["overflow-hidden"]}`}>{postDataObj.title}</h1>
    <div className={`${styles["flex"]} ${styles["flex-col"]} ${styles["mt-1"]}`}>
      <time className={`${styles["text-xs"]} ${styles["md:text-base"]} ${styles["opacity-50"]} ${styles["overflow-ellipsis"]} ${styles["overflow-hidden"]}`}>{new Date(postDataObj.timesent).toLocaleString()}</time>
      <span className={`${styles["text-xs"]} ${styles["md:text-base"]} ${styles["mt-1"]} ${styles["overflow-ellipsis"]} ${styles["overflow-hidden"]}`}>Author: @<Link className={`${styles["link"]}`} startTransition={startTransition} redirectPath={`/profile/${postDataObj.sender.uid}`} setTransitionState={setTransitionState} setPath={setPath} path={path} transition={"maskRL"}>{postDataObj.sender.uid}</Link></span>
    </div>

    {
      (currUser.uid === postDataObj.sender.uid)
      ? (<>
          <label htmlFor={deleteModalID} className={`${styles["btn"]} ${styles["btn-error"]} ${styles["w-full"]} ${styles["mt-4"]} ${styles["md:text-xl"]} ${styles["md:h-16"]}`}>Delete</label>

          <Modal id={deleteModalID}>
            <div className={`${styles["flex"]} ${styles["flex-col"]} ${styles["items-center"]} ${styles["gap-4"]} ${styles["pb-6"]}`}>

              <div className={`${styles["flex"]} ${styles["flex-col"]} ${styles["gap-2"]}`}>
                <h1 className={`${styles["font-bold"]} ${styles["md:3xl"]} ${styles["md:h-16"]} ${styles["md:py-4"]}`}>Delete Post</h1>
                <h2 className={`${styles["opacity-70"]}`}>Are you sure you want to delete this post?</h2>
              </div>

              <div className={`${styles["text-left"]} ${styles["w-full"]} ${styles["px-4"]}`}>
                <p><span className={`${styles["font-bold"]}`}>Title:</span> {postDataObj.title}</p>
                <p><span className={`${styles["font-bold"]}`}>Time Posted:</span> {new Date(postDataObj.timesent).toLocaleString()}</p>
              </div>
              
              <div className={`${styles["w-full"]} ${styles["flex"]} ${styles["flex-col"]} ${styles["gap-2"]} ${styles["p-2"]}`}>
                <button className={`${styles["btn"]} ${styles["btn-secondary"]} ${styles["md:h-16"]} ${styles["md:text-xl"]} ${styles["w-full"]}`} onClick={() => { document.getElementById(`${deleteModalID}-close`)?.click() }}>No</button>
                <button className={`${styles["btn"]} ${styles["btn-error"]} ${styles["md:h-16"]} ${styles["md:text-xl"]} ${styles["w-full"]}`} onClick={attemptDeletePost}>Yes</button>
              </div>

            </div>
          </Modal>
        </>)
      : null
    }

    <label htmlFor={readModalID} className={`${styles["btn"]} ${styles["btn-primary"]} ${styles["w-full"]} ${styles["mt-4"]} ${styles["md:text-xl"]} ${styles["md:h-16"]}`}>Read</label>

    <Modal id={readModalID} onOpen={() => { setLoadPFP(true); }}>
      <div className={`${styles["flex"]} ${styles["flex-col"]} ${styles["items-center"]} ${styles["gap-4"]} ${styles["pb-6"]}`}>
        <h1 className={`${styles["font-bold"]} ${styles["text-2xl"]} ${styles["md:text-4xl"]}`}>{postDataObj.title}</h1>
        <div ref={postContentDivRef} className={`${styles["text-left"]} ${styles["w-full"]} ${styles["px-4"]} ${styles["r-norm-list"]}`}></div>
        <MiniProfile user={postDataObj.sender} startTransition={startTransition} setTransitionState={setTransitionState} setPath={setPath} path={path} loadImage={loadPFP}></MiniProfile>
      </div>
    </Modal>
  </div>);
};
