import { useCallback, useEffect } from "react";
import { getCurrUser, signOut } from "../../connections";
import { OnEventSetter, ReactTransitionStarter, TransitionState } from "../../types";
import { redirect } from "../App";
import { User } from "../../../../lib";

export const SignOut = ({ startTransition, setTransitionState, setPath, setCurrUser }: { startTransition: ReactTransitionStarter, setTransitionState: OnEventSetter<TransitionState>, setPath: OnEventSetter<string>, setCurrUser: OnEventSetter<User | false | undefined> }) => {
  const signOutCallback = useCallback(async () => {
    await signOut();
    setCurrUser(await getCurrUser());
    redirect(startTransition, "/", "maskBT", "maskBT", setTransitionState, setPath);
  }, [setCurrUser, setPath, setTransitionState, startTransition]);

  useEffect(() => {
    signOutCallback();
  }, [signOutCallback]);

  return null;
};