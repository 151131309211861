import { useEffect, useState } from "react";
import { OnEventSetter, ReactTransitionStarter, TransitionState } from "../../types";
import { User } from "../../../../lib";
import styles from "../../css/dist/index.module.css";
import { getAllUsers } from "../../connections";
import { LoadingIcon } from "../util/loadingicon";
import { MiniProfile } from "../util/profileparts";


const userSortByNonincVerif = (a: User, b: User) => {
  if (a.verified === b.verified) return Math.ceil(Math.random() - 0.5);  // randomize order within a verif level
  return b.verified - a.verified;
};

export const AllUsers = ({ currUser, startTransition, setTransitionState, setPath, path }: { currUser: User, startTransition: ReactTransitionStarter, setTransitionState: OnEventSetter<TransitionState>, setPath: OnEventSetter<string>, path: string }) => {
  const [users, setUsers] = useState([] as User[]);

  useEffect(() => {
    getAllUsers().then((userData) => {
      setUsers(userData?.sort(userSortByNonincVerif)??[]);
    });
  }, []);

  return (<div className={`${styles["w-full"]} ${styles["flex"]} ${styles["flex-col"]} ${styles["items-center"]} ${styles["max-w-6xl"]} ${styles["my-12"]} ${styles["mx-auto"]}`}>

    <h1 className={`${styles["font-bold"]} ${styles["lg:text-8xl"]} ${styles["text-6xl"]} ${styles["mb-2"]} ${styles["bg-gradient-to-br"]} ${styles["from-primary"]} ${styles["to-secondary"]} ${styles["text-transparent"]} ${styles["bg-clip-text"]}`}>Badavas.</h1>

    <h2 className={`${styles["font-bold"]} ${styles["text-xl"]} ${styles["mb-8"]} ${styles["text-center"]}`}>User List.</h2>

    <div className={`${styles["w-full"]} ${styles["gap-4"]} ${styles["flex"]} ${styles["flex"]} ${styles["flex-wrap"]} ${styles["items-center"]} ${styles["justify-center"]}`}>
    {
      users.length === 0
      ? <LoadingIcon></LoadingIcon>
      : users.map((userData, ix) => {
          return (<UserDisplay otherUser={userData} startTransition={startTransition} setTransitionState={setTransitionState} setPath={setPath} path={path} index={ix} key={ix}></UserDisplay>);
        })
    }
    </div>

  </div>);
};

const UserDisplay = ({ otherUser, startTransition, setTransitionState, setPath, path, index }: { otherUser: User, startTransition: ReactTransitionStarter, setTransitionState: OnEventSetter<TransitionState>, setPath: OnEventSetter<string>, path: string, index: number }) => {
  return (<MiniProfile user={otherUser} startTransition={startTransition} setTransitionState={setTransitionState} setPath={setPath} path={path} noPFP={true} noSupporterDisplay={true} className={`${styles["w-full"]}`} innerClassName={`${styles["w-full"]} ${styles["max-w-72"]}`}></MiniProfile>);
};
