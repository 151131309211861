import { ForwardedRef, forwardRef, KeyboardEvent, useCallback, useRef, useState } from "react";
import { User } from "../../../../lib";
import styles from "../../css/dist/index.module.css";
import { OnEventSetter, TransitionState } from "../../types";
import { Link } from "./link";
import { MoonIcon, SearchIcon, SunIcon, HamburgerIcon } from "../svg/svgs";
import { Modal } from "./modal";
import { runSearch } from "../../connections";
import { MiniProfile } from "./profileparts";
import { LoadingIcon } from "./loadingicon";
import { ErrorNotif } from "./errornotif";

export interface NavbarData {
  targetPath: string,
  name: string,
};

export const navbarLinks = {
  signIn: {
    targetPath: "/signin",
    name: "Sign In"
  } as NavbarData,
  signUp: {
    targetPath: "/signup",
    name: "Sign Up"
  } as NavbarData,
  signOut: {
    targetPath: "/signout",
    name: "Sign Out"
  } as NavbarData,
  home: {
    targetPath: "/",
    name: "Home"
  } as NavbarData,
  network: {
    targetPath: "/network",
    name: "Network"
  } as NavbarData,
  recommendations: {
    targetPath: "/recommendations",
    name: "Recommendations"
  } as NavbarData,
  posts: {
    targetPath: "/posts",
    name: "Posts"
  } as NavbarData,
  conversations: {
    targetPath: "/chat",
    name: "Conversations"
  } as NavbarData,
  upload: {
    targetPath: "/upload",
    name: "Upload"
  } as NavbarData,
  verify: {
    targetPath: "/verify",
    name: "Verify"
  } as NavbarData,
  myProfile: {
    targetPath: "/profile/{CURR_UID}",
    name: "My Profile"
  } as NavbarData,
  allUsers: {
    targetPath: "/all-users",
    name: "All Users"
  } as NavbarData,
};

export const Navbar = forwardRef(({ links, startTransition, setTransitionState, setPath, path, currUser, theme, setTheme }: {
  links: (keyof typeof navbarLinks)[],
  startTransition: React.TransitionStartFunction,
  setTransitionState: OnEventSetter<TransitionState>,
  setPath: OnEventSetter<string>,
  path: string,
  currUser: User | false | undefined,
  theme: string,
  setTheme: (newTheme: string) => void,
}, ref: ForwardedRef<HTMLDivElement>) => {
  const unfocusOnClick = useCallback(() => {
    document.getElementById("navbar-drawer")?.click();
  }, []);


  const [errorText, setErrorText] = useState<string | undefined>();


  const SMART_SEARCH_CHARS_MAX_LENGTH = 512; const _SSCML = SMART_SEARCH_CHARS_MAX_LENGTH;

  const [suggestions, setSuggestions] = useState<[User, User, User] | undefined>();
  const smartSearchInputRef = useRef<HTMLInputElement>(null);
  const [allowedSearch, setAllowedSearch] = useState(false);
  const [attemptingSearch, setAttemptingSearch] = useState(false);
  const failAttempt = useCallback(() => {
    setAttemptingSearch(false);
  }, []);
  const attemptSmartSearch = useCallback(() => {
    setAttemptingSearch(true);

    if (!allowedSearch || attemptingSearch) {
      failAttempt();
      return;
    }

    if (!smartSearchInputRef.current) {
      failAttempt();
      setErrorText("Something unexpected happened... please refresh and try again.");
      return;
    }

    const queryText = smartSearchInputRef.current.value;
    if (queryText.length > _SSCML) {
      failAttempt();
      setErrorText(`Max search query length is ${_SSCML} characters (recieved ${queryText.length} characters)`);
      return;
    }

    runSearch(queryText).then((_suggestions) => {
      if (!_suggestions) {
        failAttempt();
        setErrorText("Something unexpected happened... please refresh and try again, or change your query");
        return;  // could also do return setErrorText(...); but wld be confusing loll bc return a inner function that returns a void implies the inner function returns smth other than void bc conventions
      }
      setSuggestions(_suggestions);
      setAttemptingSearch(false);
    });
  }, [_SSCML, allowedSearch, attemptingSearch, failAttempt]);


  const handleSearchKeyup = useCallback((evt: KeyboardEvent) => {
    if (evt.key === "Enter") {
      attemptSmartSearch();
    }
  }, [attemptSmartSearch]);


  const chooseTheme = useCallback((chosenTheme: string) => {
    setTheme(chosenTheme);
    localStorage.setItem("theme", chosenTheme);
  }, [setTheme]);


  return (<nav className={`${styles.navbar} ${styles["bg-base-100"]} ${styles["z-50"]} ${styles["relative"]}`} ref={ref}>
    <div className={`${styles["navbar-start"]} ${styles["w-14"]}`}>
      <div className={`${styles["drawer"]}`}>
        {/* OPEN DRAWER FUNCTIONALITY */}
        <input id="navbar-drawer" type="checkbox" readOnly className={styles["drawer-toggle"]} />
        <div className={`${styles["drawer-content"]}`}>
          <label htmlFor="navbar-drawer" className={`${styles.btn} ${styles["btn-ghost"]} ${styles["btn-circle"]} ${styles["drawer-button"]}`}>
            <HamburgerIcon className={`${styles["h-5"]} ${styles["w-5"]}`}></HamburgerIcon>
          </label>
        </div>

        {/* DRAWER LINKS */}
        <div className={`${styles["drawer-side"]} ${styles["flex"]} ${styles["!overflow-hidden"]} ${styles["flex-col"]} ${styles["justify-between"]}`} style={{ animationDuration: "500ms" }}>
          <label htmlFor="navbar-drawer" aria-label="close sidebar" className={`${styles["drawer-overlay"]} ${styles["!z-10"]} ${styles["!fixed"]} ${styles["!top-0"]} ${styles["!left-0"]} ${styles["!right-0"]} ${styles["!bottom-0"]}`}></label>
          <ul className={`${styles.menu} ${styles["bg-base-200"]} ${styles["text-base-content"]} ${styles["min-h-full"]} ${styles["w-80"]} ${styles["p-4"]} ${styles["!z-10"]}`}>
            <span className={`${styles["text-primary"]} ${styles["font-inter"]} ${styles["text-3xl"]} ${styles["font-bold"]} ${styles["m-3"]}`}>Badavas.</span>
            {
              links.map((linkData, ix) => {
                const matchExact = new Set(["myProfile", "verify"]);

                const { name: linkName, targetPath: linkTargetPath } = navbarLinks[linkData];
                return (<li key={ix} onClick={unfocusOnClick}><Link startTransition={startTransition} path={path} setTransitionState={setTransitionState} setPath={setPath} redirectPath={linkTargetPath.replaceAll("{CURR_UID}", currUser ? currUser.uid : "")} transition={linkTargetPath === "/signout" ? "instant" : "maskRL"} ifCurrentClasses={`${styles["link-primary"]}`} ifNotCurrentClasses={`${styles["link-hover"]}`} matchOnlyURLStart={!matchExact.has(linkData)}>{linkName}</Link></li>);
              })
            }
          </ul>
          <p className={`${styles["-translate-y-full"]} ${styles["pl-4"]} ${styles["pb-4"]} ${styles["z-20"]} ${styles["text-xs"]} ${styles["opacity-80"]}`}>© {new Date().getFullYear()} Badavas - All rights reserved</p>
        </div>

      </div>
    </div>

    {/* NAVBAR "Badavas." TEXT */}
    <div className={`${styles["flex-1"]} ${styles["mr-[-3rem]"]}`}>
      <h1 className={`${styles["font-bold"]} ${styles["text-primary"]} ${styles["text-2xl"]} ${styles["font-inter"]}`}>Badavas.</h1>
    </div>

    {/* NAVBAR CENTER */}
    <div className={`${styles["flex-1"]} ${styles["justify-center"]} ${styles["hidden"]} ${styles["sm:block"]}`}>
      {
        currUser
        ? (<>
          <label htmlFor="search-modal" className={`${styles["btn"]} ${styles["btn-ghost"]} ${styles["rounded-full"]} ${styles["h-12"]} ${styles["min-w-12"]} ${styles["mr-2"]} ${styles["flex"]} ${styles["gap-2"]} ${styles["items-center"]}`}>
            <div className={`${styles["hidden"]} ${styles["sm:block"]}`}>User Search</div>
            <SearchIcon className={`${styles["h-5"]} ${styles["w-5"]} ${styles["stroke-primary"]}`}></SearchIcon>
          </label>
        </>)
        : null
      }
    </div>

    {/* NAVBAR END */}
    <div className={`${styles["flex-1"]} ${styles["justify-end"]}`}>
      {
        currUser
        ? (<>
          <label htmlFor="search-modal" className={`${styles["btn"]} ${styles["btn-ghost"]} ${styles["btn-circle"]} ${styles["sm:hidden"]} ${styles["block"]} ${styles["flex"]} ${styles["items-center"]} ${styles["justify-center"]}`}>
            <SearchIcon className={`${styles["h-5"]} ${styles["w-5"]} ${styles["stroke-primary"]}`}></SearchIcon>
          </label>
        </>)
        : null
      }
      <label className={`${styles.flex} ${styles["cursor-pointer"]} ${styles["gap-2"]} ${styles["items-center"]}`}>
        <MoonIcon></MoonIcon>
        <input type="checkbox" checked={theme === "light"} className={`${styles.toggle}`} onClick={() => {
          if (theme === "dark") {
            chooseTheme("light");
            return;
          }
          chooseTheme("dark");
        }} />
        <SunIcon></SunIcon>
      </label>
    </div>

    <Modal id={"search-modal"}>
      <div className={`${styles["flex"]} ${styles["flex-col"]} ${styles["gap-4"]} ${styles["pb-6"]}`}>
        <input type="text" placeholder={`e.g. I want a user named ____.`} className={`${styles["input"]} ${styles["input-bordered"]} ${styles["input-ghost"]} ${styles["mt-4"]}`} ref={smartSearchInputRef} onKeyUp={handleSearchKeyup} onInput={() => { if (smartSearchInputRef.current && smartSearchInputRef.current.value?.length) setAllowedSearch(true); else setAllowedSearch(false); }} />

        <button className={`${styles["btn"]} ${styles["btn-primary"]} ${(!allowedSearch || attemptingSearch) ? `${styles["btn-disabled"]}` : ``}`} onClick={attemptSmartSearch}>Smart Search</button>
        
        {
          (suggestions || attemptingSearch)
          ? (<div className={`${styles["flex"]} ${styles["flex-col"]} ${styles["gap-4"]} ${styles["border"]} ${styles["border-base-content"]} ${styles["border-opacity-20"]} ${styles["rounded-lg"]} ${styles["items-center"]} ${styles["p-4"]}`}>
              { attemptingSearch ? <LoadingIcon></LoadingIcon> : null }
              {
                suggestions
                ? suggestions.map((user, ix) => {
                    return (<MiniProfile className={`${styles["w-full"]}`} user={user} startTransition={startTransition} setTransitionState={setTransitionState} setPath={setPath} path={path} onClick={() => { document.getElementById("search-modal-close")?.click(); }} key={`mpsk${ix}`}></MiniProfile>);
                  })
                : null
              }
            </div>)
          : null
        }
      </div>
    </Modal>

    <ErrorNotif errorText={errorText} onClose={() => { setErrorText(undefined); }}></ErrorNotif>
  </nav>);
});