import { RefObject, useCallback, useEffect, useRef, useState } from "react";
import styles from "../../css/dist/index.module.css";
import { OnEventSetter, ReactTransitionStarter, TransitionState } from "../../types";
import { User } from "../../../../lib";
import { activatePasswordResetCode, checkPWResetCodeIsValid, PWCodeSendResponse, sendPasswordResetEmail } from "../../connections";
import { VALIDITY_CHECKS } from "../../validity-checks";
import { redirect } from "../App";
import { SingleFullInputStep } from "../util/inputtemplates";
import { accentButtonClassName } from "../util/classnames";

export const ResetPasswordRequester = ({ currUser, startTransition, setTransitionState, setPath, path, mainContainerRef }: { currUser: User | false | undefined, startTransition: ReactTransitionStarter, setTransitionState: OnEventSetter<TransitionState>, setPath: OnEventSetter<string>, path: string, mainContainerRef: RefObject<HTMLDivElement> }) => {

  const [errorText, setErrorText] = useState<string | undefined>();

  const uidInputRef = useRef<HTMLInputElement>(null);
  const emailInputRef = useRef<HTMLInputElement>(null);

  const [attemptingPWReset, setAttemptingPasswordReset] = useState(false);
  const failAttempt = useCallback(() => {
    setAttemptingPasswordReset(false);
  }, []);
  const [sentEmail, setSentEmail] = useState(false);
  const attemptPWReset = useCallback(async () => {
    if (attemptingPWReset || !uidInputRef.current || !emailInputRef.current) return;

    setAttemptingPasswordReset(true);

    if (!uidInputRef.current.value.length || !emailInputRef.current.value.length) {
      setErrorText("We should fill out all inputs...");
      failAttempt();
      return;
    }

    const responseCode = await sendPasswordResetEmail(uidInputRef.current.value, emailInputRef.current.value);

    if (responseCode === PWCodeSendResponse.UnknownFailure) {
      setErrorText("Please wait three minutes before sending password reset request (if you still recieve this message after three minutes, message us at contact@badavas.com).");
      failAttempt();
      return;
    }

    if (responseCode === PWCodeSendResponse.NoUser) {
      setErrorText("Invalid credentials");
      failAttempt();
      return;
    }

    setSentEmail(true);
  }, [attemptingPWReset, failAttempt]);

  return (<SingleFullInputStep errorText={errorText} onErrorClose={() => { setErrorText(undefined); }} label="Reset Password">

    <input type="text" placeholder="User ID" className={`${styles.input}`} ref={uidInputRef}></input>
    <input type="email" placeholder="Email" className={`${styles.input}`} ref={emailInputRef}></input>
    
    <button

      className={`
        ${sentEmail
        ? `${styles["btn"]} ${styles["btn-primary"]} ${styles["btn-disabled"]}`
        : `${accentButtonClassName}`}`}
      
      onClick={attemptPWReset}
      
    >{sentEmail ? "Email Sent" : "Send Password Reset Email"}</button>

  </SingleFullInputStep>);

};

export const PasswordResetter = ({ currUser, startTransition, setTransitionState, setPath, path, mainContainerRef }: { currUser: User | false | undefined, startTransition: ReactTransitionStarter, setTransitionState: OnEventSetter<TransitionState>, setPath: OnEventSetter<string>, path: string, mainContainerRef: RefObject<HTMLDivElement> }) => {

  const [errorText, setErrorText] = useState<string | undefined>();

  const passwordInput1Ref = useRef<HTMLInputElement>(null);
  const passwordInput2Ref = useRef<HTMLInputElement>(null);


  const [code, setCode] = useState("");
  useEffect(() => {
    const pathSplit = path.split("/");
    if (pathSplit.length !== 3) {
      setCode("");
      return;
    }
    setCode(pathSplit[2]);
  }, [path]);


  const [codeValid, setCodeValid] = useState(false);
  useEffect(() => {
    if (!code) return;
    checkPWResetCodeIsValid(code).then((isValid) => {
      setCodeValid(isValid ?? false);
    });
  }, [code]);


  const [attemptingPWReset, setAttemptingPasswordReset] = useState(false);
  const failAttempt = useCallback(() => {
    setAttemptingPasswordReset(false);
  }, []);
  const attemptPWReset = useCallback(async () => {
    if (attemptingPWReset || !passwordInput1Ref.current || !passwordInput2Ref.current) return;

    setAttemptingPasswordReset(true);

    if (!codeValid) {
      failAttempt();
      setErrorText("This password reset code is invalid (or you attempted too quickly). Confirm you used the correct link.");
      return;
    }

    if (!passwordInput1Ref.current.value.length || !passwordInput2Ref.current.value.length) {
      failAttempt();
      setErrorText("We should fill out all inputs...");
      return;
    }
    
    if (passwordInput1Ref.current.value !== passwordInput2Ref.current.value) {
      failAttempt();
      setErrorText("Inputted values do not match");
      return;
    }

    const pwval = passwordInput1Ref.current.value;

    const errorResult = await VALIDITY_CHECKS["password"](pwval, pwval);
    if (errorResult) {
      failAttempt();
      setErrorText(errorResult);
      return;
    }

    activatePasswordResetCode(code, pwval).then((success) => {
      if (!success) {
        failAttempt();
        setErrorText("Something unexpected happened... please try again.");
        return;
      }
      
      redirect(startTransition, "/signin", "maskRL", "maskRL", setTransitionState, setPath);
    });
  }, [codeValid, attemptingPWReset, failAttempt, code, setPath, setTransitionState, startTransition]);


  return (<SingleFullInputStep errorText={errorText} onErrorClose={() => { setErrorText(undefined); }} label={"Reset Password"}>

    <input type="password" placeholder="Password" className={`${styles.input}`} ref={passwordInput1Ref}></input>
    <input type="password" placeholder="Confirm Password" className={`${styles.input}`} ref={passwordInput2Ref}></input>

    <button className={`${accentButtonClassName}`} onClick={attemptPWReset}>Reset Password</button>

    <p className={`${styles["text-xs"]} ${styles["text-center"]} ${styles["opacity-70"]}`}>You will be redirected to sign in after a successful password reset.</p>

  </SingleFullInputStep>);
};