import { ErrorNotif } from "./errornotif";
import styles from "../../css/dist/index.module.css";
import { BlurTextSetup } from "./gradienttext";
import { JSXElements } from "../../types";

export const SingleFullInputStep = ({ errorText, onErrorClose, children, label }: { errorText: string | undefined, onErrorClose: () => void, children: JSXElements, label?: string }) => {
  return (<div className={`${styles["w-full"]} ${styles["h-full"]} ${styles["flex"]} ${styles["justify-center"]} ${styles["items-center"]}`}>

    <div className={`${styles["w-[600px]"]} ${styles["flex"]} ${styles["justify-center"]} ${styles["items-center"]}`}>
        
      <div className={`${styles["hidden"]} ${styles["md:flex"]} ${styles["flex-col"]} ${styles["items-center"]} ${styles["gap-2"]}`}>
        <BlurTextSetup>
          <span className={`${styles["font-bold"]} ${styles["font-inter"]} ${styles["text-[8rem]"]} ${styles["text-primary"]}`}>B.</span>
        </BlurTextSetup>
      </div>

      <div className={`${styles["flex"]} ${styles["flex-col"]} ${styles["items-center"]} ${styles["gap-4"]} ${styles["md:pl-8"]} ${styles["md:border-primary/40"]} ${styles["md:py-4"]} ${styles["md:ml-8"]} ${styles["md:border-l"]}`}>
        <span className={`${styles["font-bold"]} ${styles["text-xl"]}`}>{ label }</span>
        { children }
      </div>

      <ErrorNotif errorText={errorText} onClose={onErrorClose}></ErrorNotif>
    </div>
  </div>);
};
