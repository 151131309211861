import { RefObject, useCallback, useEffect, useReducer, useRef, useState } from "react";
import { User } from "../../../../lib";
import { getMessageCount, getMessages, getPFP, MessageData, sendMessage, useConversations } from "../../connections";
import styles from "../../css/dist/index.module.css";
import { OnEventSetter, ReactTransitionStarter, TransitionState } from "../../types";
import { ChatIcon } from "../svg/svgs";
import { Modal } from "../util/modal";
import { MiniProfile } from "../util/profileparts";
import { ErrorNotif } from "../util/errornotif";
import { Link } from "../util/link";

export const Conversations = ({ currUser, startTransition, setTransitionState, setPath, path, mainContainerRef }: { currUser: User, startTransition: ReactTransitionStarter, setTransitionState: OnEventSetter<TransitionState>, setPath: OnEventSetter<string>, path: string, mainContainerRef: RefObject<HTMLDivElement> }) => {
  const { conversations, locallyMarkRead } = useConversations(currUser);


  const [otherUserUID, setOtherUserUID] = useState<string | undefined>();
  const [otherUserUIDLoading, setOtherUserUIDLoading] = useState(true);
  useEffect(() => {
    const pathComponents = path.split("/");
    if (pathComponents.length < 3) {
      setOtherUserUID(undefined);
      setOtherUserUIDLoading(false);
      return;
    }
    setOtherUserUID(pathComponents[2]);
    setOtherUserUIDLoading(false);
  }, [path]);


  const [otherUserPfpURL, setOtherUserPfpURL] = useState("");
  useEffect(() => {
    if (!otherUserUID) return;
    getPFP(otherUserUID).then((pfpBlob) => {
      setOtherUserPfpURL(URL.createObjectURL(pfpBlob));
    });
  }, [otherUserUID]);

  const [currUserPfpURL, setCurrUserPfpURL] = useState("");
  useEffect(() => {
    if (!currUser) return;
    getPFP(currUser.uid).then((pfpBlob) => {
      setCurrUserPfpURL(URL.createObjectURL(pfpBlob));
    });
  }, [currUser]);


  const CHAT_MESSAGE_LIMIT_INC = 25; const _CMLI = CHAT_MESSAGE_LIMIT_INC;
  const [majorLoadState, updateMajorLoadState] = useReducer((state: number) => { return (state + 1) % 2 }, -1);
  const [msgLimit, increaseMsgLimit] = useReducer((state: number, action: "MAJOR_LOAD" | "MINOR_LOAD") => {
    switch (action) {
      case "MAJOR_LOAD":
        updateMajorLoadState();
        return state + _CMLI;  // no need for break bc return ends switch anyway
      case "MINOR_LOAD":
        return state + 1;
    }
  }, _CMLI);


  const [messageDataObjs, setMessageDataObjs] = useState<MessageData[]>([]);
  const [lastTop, setLastTop] = useState<Element | undefined>();
  useEffect(() => {
    if (!otherUserUID) return;

    const chatElements = document.getElementsByClassName(styles["chat"]);

    const leastRecentLoadedMessageElement = chatElements[0];
    if (!lastTop || leastRecentLoadedMessageElement.id !== lastTop.id) {
      setLastTop(leastRecentLoadedMessageElement);
    }

    getMessages(otherUserUID, msgLimit).then((messages) => {
      setMessageDataObjs(messages ?? []);
      mainContainerRef.current?.scrollTo(0, 0);
    });
  }, [otherUserUID, msgLimit, mainContainerRef, lastTop]);//problem: need to distinguish between major and minor load scroll behavior


  const [convMsgCnt, setConvMsgCnt] = useState(0);
  useEffect(() => {
    if (!otherUserUID) return;
    getMessageCount(otherUserUID).then((cnt) => {
      setConvMsgCnt(cnt);
      locallyMarkRead(otherUserUID);
    });
  }, [otherUserUID, locallyMarkRead]);


  const [hasUnreadMessage, setHasUnreadMessage] = useState(false);
  useEffect(() => {
    for (let conversation of conversations) {
      if (!conversation.unreadMessage) continue;

      if (conversation.otherUser.uid === otherUserUID) {
        increaseMsgLimit("MINOR_LOAD");
        continue;
      }

      setHasUnreadMessage(true);

      return;
    }
    setHasUnreadMessage(false);
  }, [conversations, otherUserUID]);


  const [errorText, setErrorText] = useState<string | undefined>();


  const MSG_CHAR_LIMIT = 1024;


  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const sendMessageCallback = useCallback(async () => {
    if (!textareaRef.current || !otherUserUID) return;

    const msgText = textareaRef.current.value.trim();

    if (!msgText.length) return;
    
    if (msgText.length > MSG_CHAR_LIMIT) {
      setErrorText(`Your message is too long (max characters is ${MSG_CHAR_LIMIT}, got ${msgText.length}).`);
      return;
    }

    textareaRef.current.value = "";
    
    await sendMessage(otherUserUID, msgText);
    increaseMsgLimit("MINOR_LOAD");
  }, [textareaRef, otherUserUID]);

  const handleEnterInTextarea = useCallback((evt: React.KeyboardEvent) => {
    if (evt.key === "Enter" && !evt.shiftKey) {
      evt.preventDefault();
      sendMessageCallback();
    }
  }, [sendMessageCallback]);


  const closeConversationsModal = useCallback(() => {
    document.getElementById("conversations-modal-close")?.click();
  }, []);


  const messageListRef = useRef<HTMLDivElement>(null);
  
  // SCROLL LOCKING
  const [hadFirstLock, setHadFirstLock] = useState(false);
  useEffect(() => {
    const chatElements = document.getElementsByClassName(styles["chat"]);

    if (!messageDataObjs.length || chatElements.length < 2 || !messageListRef.current) return;

    const DIST_FROM_BOTTOM_THRESHOLD_FOR_SCROLL_LOCK_PX = 0; const _DFBTFSLPX = DIST_FROM_BOTTOM_THRESHOLD_FOR_SCROLL_LOCK_PX;

    const mostRecentMessageElementContent = chatElements[chatElements.length - 1].querySelectorAll("*");
    const secondMostRecentMessageElement = chatElements[chatElements.length - 2];

    console.log(messageListRef.current.getBoundingClientRect().bottom - secondMostRecentMessageElement.getBoundingClientRect().bottom, _DFBTFSLPX);
    
    if (messageListRef.current.getBoundingClientRect().bottom - secondMostRecentMessageElement.getBoundingClientRect().bottom > _DFBTFSLPX || !hadFirstLock) {
      // scroll locked
      setHadFirstLock(true);
      mostRecentMessageElementContent[mostRecentMessageElementContent.length - 1].scrollIntoView();
    }
  }, [messageDataObjs, hadFirstLock]);
  useEffect(() => {
    if (!hadFirstLock || !lastTop || majorLoadState === -1) return;

    lastTop.scrollIntoView();
  }, [hadFirstLock, majorLoadState, lastTop]);


  const convModalOpenerRef = useRef<HTMLLabelElement>(null);
  useEffect(() => {
    if (!otherUserUID && !otherUserUIDLoading) convModalOpenerRef.current?.click();
  }, [otherUserUID, otherUserUIDLoading]);

  
  const DURATION_FOR_MESSAGE_SEQUENCE_SEPARATION_THRESHOLD_MS = 60 * 1000; const _DFMSSTMS = DURATION_FOR_MESSAGE_SEQUENCE_SEPARATION_THRESHOLD_MS;

  // ping svg if one unread
  return (<div className={`${styles["mx-auto"]} ${styles["w-full"]} ${styles["h-full"]} ${styles["flex"]} ${styles["justify-center"]}`}>
    <div ref={messageListRef} className={`${styles["flex"]} ${styles["flex-col"]} ${styles["h-full"]} ${styles["w-full"]} ${styles["justify-center"]} ${styles["items-center"]}`}>
      {/* WHO MESSAGING BAR */}
      {
        otherUserUID
        ? (<div className={`${styles["flex"]} ${styles["w-full"]} ${styles["justify-center"]} ${styles["items-center"]} ${styles["gap-4"]} ${styles["bg-base-100"]} ${styles["py-4"]}`}>
            {/* eslint-disable-next-line */}
            <img src={otherUserPfpURL} className={`${styles["h-10"]} ${styles["rounded-lg"]}`} alt={"profile picture"}></img>
            <Link className={`${styles["font-extrabold"]} ${styles["text-base-content"]} ${styles["text-xl"]} ${styles["link"]}`} startTransition={startTransition} redirectPath={`/profile/${otherUserUID}`} setTransitionState={setTransitionState} setPath={setPath} path={path} transition={"maskRL"}>@{otherUserUID}</Link>
          </div>)
        : null
      }

      {/* MESSAGE LIST */}
      {
        otherUserUID
        ? (<div className={`${styles["flex-1"]} ${styles["h-full"]} ${styles["overflow-y-scroll"]} ${styles["w-full"]}`}>
            <div className={`${styles["p-5"]} ${styles["max-w-xl"]} ${styles["mx-auto"]} ${styles["flex"]} ${styles["flex-col"]} ${styles["justify-end"]} ${styles["h-full"]} ${styles["overflow-y-visible"]}`}>
              <div className={`${styles["h-full"]}`}>
                {
                  convMsgCnt > msgLimit  // i.e. if there are more messages to load, msgLimit == messages loaded / to-be loaded
                  ? (<div className={`${styles["flex"]} ${styles["justify-center"]} ${styles["mb-8"]}`}>
                      <button className={`${styles["btn"]} ${styles["btn-primary"]}`} onClick={() => {
                        increaseMsgLimit("MAJOR_LOAD");
                      }}>Load More</button>
                    </div>)
                  : null
                }
                {
                  messageDataObjs.map((msgDataObj, ix) => {
                    const prevMsg: MessageData | null = (ix - 1 >= 0) ? messageDataObjs[ix - 1] : null;
                    const nextMsg: MessageData | null = (ix + 1 < messageDataObjs.length) ? messageDataObjs[ix + 1] : null;
                    const fromCurr: boolean = msgDataObj.sender.uid === currUser.uid;

                    return (<Message

                      messageData={msgDataObj} pfpURL={fromCurr ? currUserPfpURL : otherUserPfpURL} fromCurr={fromCurr}

                      sequenceStart={
                        !prevMsg ||
                        prevMsg.sender.uid !== msgDataObj.sender.uid ||
                        new Date(msgDataObj.timeSent).getTime() - new Date(prevMsg.timeSent).getTime() >= _DFMSSTMS
                      }
                      
                      sequenceEnd={
                        !nextMsg ||
                        nextMsg.sender.uid !== msgDataObj.sender.uid ||
                        new Date(nextMsg.timeSent).getTime() - new Date(msgDataObj.timeSent).getTime() >= _DFMSSTMS
                      }

                      key={msgDataObj.timeSent}
                      
                    ></Message>);
                  })
                }
              </div>
            </div>
          </div>)
        : null
      }

      <div className={`${styles["flex"]} ${styles["justify-center"]} ${styles["w-full"]} ${styles["items-center"]}`}>
        <div className={`${styles["flex"]} ${styles["max-w-3xl"]} ${styles["w-full"]} ${styles["py-6"]} ${styles["justify-center"]} ${styles["items-center"]} ${otherUserUID ? "" : styles["h-full"]}`}>
          {/* VIEW OTHER CONVERSATION FUNCTIONALITY */}
          <div>
            <label ref={convModalOpenerRef} htmlFor="conversations-modal" className={`${styles.btn} ${styles["btn-circle"]} ${otherUserUID ? styles["btn-ghost"] : `${styles["btn-primary"]} ${styles["w-20"]} ${styles["h-20"]} ${styles["ring-2"]} ${styles["ring-offset-2"]} ${styles["ring-primary"]} ${styles["ring-offset-base-100"]} ${styles["idle-zoom-xl"]}`}`}>
              <div className={`${styles["indicator"]}`}>
                {/* {
                  hasUnreadMessage
                  ? (
                      otherUserUID
                      ? (<span className={`${styles["indicator-item"]} ${styles["badge"]} ${styles["badge-primary"]} ${styles["text-primary"]} ${styles["font-bold"]}`}></span>)
                      : (<span className={`${styles["indicator-item"]} ${styles["badge"]} ${styles["badge-base-100"]} ${styles["ring"]} ${styles["ring-4"]} ${styles["ring-primary"]} ${styles["text-primary"]} ${styles["font-bold"]}`}>!</span>)
                    )
                  : null
                } */}
                <ChatIcon className={`${styles["h-5"]} ${styles["w-5"]}`}></ChatIcon>

                {
                  hasUnreadMessage
                  ? (
                      <div className={`${styles["indicator-item"]} ${styles["translate-x-1/2"]} ${styles["text-error"]}`}>
                        <div className={`${styles["animate-ping"]} ${styles["text-lg"]} ${styles["absolute"]}`}>⬤</div>
                        <div className={`${styles["text-lg"]}`}>⬤</div>
                      </div>
                    )
                  : null
                }
              </div>
            </label>
            <Modal id="conversations-modal">

              {
                conversations.length
                ? (<h1 className={`${styles["font-semibold"]} ${styles["text-lg"]} ${styles["mx-auto"]}`}>
                  {
                    otherUserUID
                    ? "Your Other Conversations"
                    : "Your Conversations"
                  }
                </h1>)
                : null
              }
              
              <div className={`${styles["relative"]} ${styles["flex"]} ${styles["flex-col"]} ${styles["items-center"]} ${styles["pr-4"]} ${styles["pb-4"]}`}>

                {
                  conversations.filter((conversation) => {
                    return conversation.otherUser.uid !== otherUserUID && currUser.uid !== otherUserUID;
                  }).map((conversation, ix) => {
                    return (<MiniProfile user={conversation.otherUser} ping={conversation.unreadMessage} startTransition={startTransition} setTransitionState={setTransitionState} setPath={setPath} path={path} className={`${styles["w-full"]}`} link={`/chat/${conversation.otherUser.uid}`} key={ix} onClick={() => { setHadFirstLock(false); closeConversationsModal(); }}></MiniProfile>);
                  })
                }

              </div>
              <label htmlFor="search-modal" className={`${styles["btn"]} ${styles["btn-accent"]} ${styles["w-full"]} ${styles["mt-4"]}`} onClick={closeConversationsModal}>Start more conversations</label>
            </Modal>
          </div>

          {/* MESSAGE SEND TEXTAREA */}
          {
            otherUserUID
            ? (<div className={`${styles["mx-3"]} ${styles["mt-3"]} ${styles["w-full"]}`}>
                <textarea className={`${styles["textarea"]} ${styles["textarea-secondary"]} ${styles["w-full"]}`} ref={textareaRef} onKeyDown={handleEnterInTextarea}></textarea>
              </div>)
            : null
          }

          {/* MESSAGE SEND OPTION */}
          {
            otherUserUID
            ? (<div>
                <button className={`${styles["btn"]} ${styles["btn-ghost"]} ${styles["font-inter"]} ${styles["btn-circle"]}`} onClick={sendMessageCallback}>{"-->"}</button>
              </div>)
            : null
          }
        </div>
      </div>
    </div>

    <ErrorNotif errorText={errorText} onClose={() => { setErrorText(undefined); }}></ErrorNotif>
  </div>);
};

const Message = ({ fromCurr, messageData, pfpURL, sequenceStart, sequenceEnd }: { sequenceEnd: boolean, sequenceStart: boolean, fromCurr: boolean, messageData: MessageData, pfpURL: string }) => {
  const DISPLAY_DATE_THRESH = 24 * 60 * 60 * 1000;

  return (<div className={`${styles["chat"]} ${fromCurr ? styles["chat-end"] : styles["chat-start"]} ${styles["p-0"]} ${styles["mt-0.5"]}`} id={`m-${messageData.timeSent}`}>
    {/* PFP */}
    <div className={`${styles["chat-image"]} ${styles["avatar"]}`}>
      <div className={`${styles["h-6"]} ${styles["rounded-full"]}`}>
        { sequenceEnd ? <img src={pfpURL} alt="avatar"></img> : null }
      </div>
    </div>

    {/* TIMESTAMP */}
    {
      sequenceStart
      ? (<div className={`${styles["chat-header"]}`}>
          {/* using neither text-primary-content nor text-neutral bc yea */}
          <time className={`${styles["text-xs"]} ${styles["opacity-80"]} ${fromCurr ? styles["text-primary"] : styles["text-neutral-content"]}`}>
            {
              Date.now() - new Date(messageData.timeSent).getTime() >= DISPLAY_DATE_THRESH
              ? new Date(messageData.timeSent).toLocaleDateString()
              : new Date(messageData.timeSent).toLocaleTimeString([], { hour: "numeric", minute: "2-digit" })
            }
          </time>
        </div>)
      : null
    }

    {/* SENDER USER ID TEXT
    {
      sequenceEnd
      ? (<div className={`${styles["chat-footer"]}`}>
        <span className={`${styles["block"]} ${styles["mt-1"]}}`}>@{messageData.sender.uid}</span>
      </div>)
      : null
    } */}

    {/* MESSAGE TEXT */}
    <div className={`${styles["break-words"]} ${styles["relative"]}
      ${
        sequenceEnd
        ? `${styles["chat-bubble"]} ${fromCurr ? styles["chat-bubble-primary"] : ""}`
        : `${styles["px-4"]} ${styles["py-2"]} ${styles["rounded-box"]} ${styles["min-h-11"]} ${styles["min-w-11"]}
          ${fromCurr ? `${styles["bg-primary"]} ${styles["text-primary-content"]}` : `${styles["bg-neutral"]} ${styles["text-neutral-content"]}`}`
      }
      ${
        fromCurr
        ? `${styles["text-right"]}`
        : `${styles["text-left"]}`
      }
    `} style={{ maxWidth: "min(50dvw, 28rem)" }}>
      {
        [...messageData.message].map((char) => {
          switch (char) {
            case "\n":
              return <br />;  // no need for break because return already ends switch
            default:
              return <span>{char}</span>;
          }
        })
      }
    </div>
  </div>);
};
