import { User } from "../../../../lib";
import { OnEventSetter, ReactTransitionStarter, TransitionState } from "../../types";
import styles from "../../css/dist/index.module.css";
import { RefObject, useCallback, useEffect, useState } from "react";
import { ErrorNotif } from "../util/errornotif";
import { activateVerifCode, getCurrUser } from "../../connections";
import { redirect } from "../App";
import { MiniProfile } from "../util/profileparts";

export const Verify = ({ currUser, startTransition, setTransitionState, setPath, path }: { currUser: User, startTransition: ReactTransitionStarter, setTransitionState: OnEventSetter<TransitionState>, setPath: OnEventSetter<string>, path: string }) => {
  // TODO: ADD MORE DETAILS ON HOW TO INCREASE A VERIFICATION LEVEL
  return (<div className={`${styles["px-5"]} ${styles["max-w-6xl"]} ${styles["w-full"]} ${styles["mx-auto"]} ${styles["flex"]} ${styles["flex-col"]} ${styles["items-center"]}`}>

    <h1 className={`${styles["font-bold"]} ${styles["text-6xl"]} ${styles["mb-2"]} ${styles["bg-gradient-to-br"]} ${styles["from-primary"]} ${styles["via-primary"]} ${styles["to-secondary"]} ${styles["text-transparent"]} ${styles["bg-clip-text"]}`}>Badavas.</h1>

    <h2 className={`${styles["font-bold"]} ${styles["text-xl"]} ${styles["mb-8"]}`}>Verification Levels.</h2>

    <div className={`${styles["join"]} ${styles["join-vertical"]} ${styles["w-full"]}`}>
      {
        [
          {
            title: "Unverified",
            desc: "The user has only created an account.",
          },
          {
            title: "Verification Level 1",
            desc: "The user has confirmed their email.",
          },
          {
            title: "Verification Level 2",
            desc: "The user has submitted documentation to support their authenticity and that their Badavas account is operated by the company they claim. To obtain level 2, email contact@badavas.com with your company's LinkedIn username and your Badavas user ID. The subject of the email should be \"Level 2 Verification Application\".",
          },
          {
            title: "Verification Level 3",
            desc: "Another user has confirmed their collaboration with this user. To obtain level 3, another user of at least verification level 2 must send an email to contact@badavas.com, confirming that they have successfully collaborated with you, with both their Badavas user ID and your user ID stated. The subject of the email should be \"Level 3 Verification Application Vouch\". You must then email contact@badavas.com as well, stating the name of the user who has collaborated with you, with both their Badavas user ID and your user ID stated. The subject of the email should be \"Level 3 Verification Application\". Note that if the user confirming that they have collaborated with you wishes to apply for Verification Level 3 as well, they have to send a separate email requesting verification, and you may not vouch for them because they have already vouched for you.",
          },
          {
            title: "Verification Level 4",
            desc: "The user has been an active member of the Badavas community. To obtain level 4, you must be a consistent and genuine member of the Badavas community for at least two months. Note that \"consistent\" and \"genuine\" are highly subjective, and The Badavas Team will use our own judgement when approving or rejecting your application. If you believe you satisfy the requirements of verification level 4, send an email to contact@badavas.com stating your Badavas user ID with a subject of \"Level 4 Verification Application\".",
          },
          {
            title: "Verification Level 5",
            desc: "The Badavas Team has seen this user in-operation, in real life, or on video call. To obtain level 5, send an email to contact@badavas.com stating your Badavas user ID with a subject of \"Level 5 Verification Application Meeting\", with times where your business is active and you are available to meet for approximately 15 minutes. The Badavas Team is based in New York City, so businesses located nearby may be able to schedule a real-life meeting, if they provide an address. Once this meeting is complete, send another email to contact@badavas.com stating your Badavas user ID with a subject of \"Level 5 Verification Application\".",
          }
        ].map((verifLevelDescData, ix) => {
          return (<VerificationLevelDesc title={verifLevelDescData.title} desc={verifLevelDescData.desc} key={ix}></VerificationLevelDesc>)
        })
      }
    </div>
  </div>);
};

const VerificationLevelDesc = ({ title, desc }: { title: string, desc: string }) => {
  return (<div className={`${styles["collapse"]} ${styles["collapse-arrow"]} ${styles["join-item"]} ${styles["border-base-300"]} ${styles["border"]}`}>
    <input type="radio" name="verif-accordion" />
    <div className={`${styles["collapse-title"]} ${styles["text-xl"]} ${styles["font-medium"]}`}>{ title }</div>
    <div className={styles["collapse-content"]}>
      <p>{ desc }</p>
    </div>
  </div>);
};

export const VerificationActor = ({ currUser: _, startTransition, setTransitionState, setCurrUser, setPath, path, mainContainerRef }: { currUser: User, startTransition: ReactTransitionStarter, setCurrUser: (user: User | false | undefined) => void, setTransitionState: OnEventSetter<TransitionState>, setPath: OnEventSetter<string>, path: string, mainContainerRef: RefObject<HTMLDivElement> }) => {

  const [currUser, _setCurrUser] = useState<User | undefined | "">("");
  useEffect(() => {
    getCurrUser().then((user) => { _setCurrUser(user ? user : undefined); });
  }, []);

  const [attemptingVerification, setAttemptingVerification] = useState(false);

  const [errorText, setErrorText] = useState<string | undefined>();

  const [requestedLevel, setRequestedLevel] = useState(-1);
  const [verifCode, setVerifCode] = useState("");
  useEffect(() => {
    const pathSplit = path.split("/");
    if (pathSplit.length !== 4 || Number.isNaN(parseInt(pathSplit[2]))) {
      setRequestedLevel(-1);
      setVerifCode("");
      return;
    }
    setRequestedLevel(parseInt(pathSplit[2]));
    setVerifCode(pathSplit[3]);
  }, [path]);


  const [allowVerify, setAllowVerify] = useState(false);
  useEffect(() => {
    if (requestedLevel === -1 || verifCode === "" || attemptingVerification) {
      setAllowVerify(false);
      return;
    }

    if (currUser === undefined) {
      redirect(startTransition, "/signin", "maskRL", "maskRL", setTransitionState, setPath);
      setAllowVerify(false);
      return;
    }

    if (currUser === "") {
      setAllowVerify(false);
      return;
    }

    if (currUser.verified === requestedLevel) {
      setAllowVerify(false);
      setErrorText(`You are already verification level ${requestedLevel}!`);
      return;
    }
    if (currUser.verified > requestedLevel) {
      setAllowVerify(false);
      setErrorText(`You cannot accept verification level ${requestedLevel} because you are of a greater verification level already! (${currUser.verified})`);
      return;
    }

    setAllowVerify(true);
  }, [currUser, requestedLevel, attemptingVerification, verifCode, setPath, setTransitionState, startTransition]);


  const failAttempt = useCallback(() => {
    setAttemptingVerification(false);
  }, []);
  const attemptVerification = useCallback(async () => {
    if (attemptingVerification) return;

    setAttemptingVerification(true);

    activateVerifCode(verifCode).then((success) => {
      if (!success) {
        failAttempt();
        setErrorText("This verification link is invalid. It might have expired; navigate to the hope page to recieve a new code!");
        return;
      }

      getCurrUser().then((user) => {
        setCurrUser(user);
        if (user) {
          redirect(startTransition, `/profile/${user.uid}`, "maskTB", "maskTB", setTransitionState, setPath);
          return;
        }
        redirect(startTransition, `/signin`, "maskRL", "maskRL", setTransitionState, setPath);
      });
    });
  }, [failAttempt, attemptingVerification, setCurrUser, setPath, setTransitionState, startTransition, verifCode]);


  return (<div className={`${styles["w-full"]} ${styles["px-6"]} ${styles["max-w-6xl"]} ${styles["mx-auto"]} ${styles["h-full"]} ${styles["flex"]} ${styles["flex-col"]} ${styles["items-center"]} ${styles["justify-center"]} ${styles["gap-6"]}`}>
      {
        allowVerify && currUser
        ? (
            <>
              <div className={`${styles["rounded-lg"]} ${styles["bg-neutral"]} ${styles["text-neutral-content"]} ${styles["p-6"]} ${styles["flex"]} ${styles["flex-col"]} ${styles["gap-6"]} ${styles["w-full"]}`}>
                <h1 className={`${styles["text-xl"]} ${styles["font-bold"]}`}>Current User:</h1>
                <MiniProfile user={currUser} innerClassName={`${styles["!p-0"]}`} startTransition={startTransition} setTransitionState={setTransitionState} setPath={setPath} path={path}></MiniProfile>
              </div>

              <div className={`${styles["rounded-lg"]} ${styles["bg-neutral"]} ${styles["w-full"]} ${styles["text-neutral-content"]} ${styles["p-6"]} ${styles["flex"]} ${styles["flex-col"]} ${styles["gap-6"]}`}>
                <button className={`${styles["btn"]} ${styles["btn-accent"]} ${styles["drop-shadow-accent"]} ${styles["md:hover:scale-[1.03]"]} ${styles["md:text-2xl"]} ${styles["text-lg"]} ${styles["hover:bg-accent"]} ${styles["transition-all"]} ${styles["duration-300"]}`} onClick={attemptVerification}>Accept verification level {requestedLevel}</button>
                <p className={`${styles["text-xs"]} ${styles["opacity-70"]}`}>You will be redirected after a successful verification level upgrade.</p>
              </div>
            </>
          )
        : null
      }

    <ErrorNotif errorText={errorText} onClose={() => { setErrorText(undefined); }}></ErrorNotif>
  </div>);


};
