import { RefObject, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { User } from "../../../../lib";
import styles from "../../css/dist/index.module.css";
import { getRecommendations } from "../../connections";
import { PieChart } from "../util/piechart";
import { OnEventSetter, ReactTransitionStarter, SingleJSXElement, TransitionState } from "../../types";
import { MiniProfile } from "../util/profileparts";
import { LoadingIcon } from "../util/loadingicon";
import { Animated } from "react-animated-css";

export const Recommendations = ({ startTransition, medium, setTransitionState, setPath, path, mainContainerRef }: { startTransition: ReactTransitionStarter, setTransitionState: OnEventSetter<TransitionState>, medium: boolean, setPath: OnEventSetter<string>, path: string, mainContainerRef: RefObject<HTMLDivElement> }) => {
  const [recommendations, setRecommendations] = useState<[User, number][]>([]);  

  useEffect(() => {
    getRecommendations().then((r) => {
      setRecommendations(r?.slice(0, 3)??[]);
      mainContainerRef.current?.scrollTo(0, 0);
    });
  }, [mainContainerRef]);

  const [recommendationsObjects, setRecommendationsObjects] = useState<SingleJSXElement[]>([]);

  const [currentRecIX, setCurrentRecIX] = useState(0);

  useEffect(() => {
    setRecommendationsObjects(recommendations.map((rec, ix) => {
      return (<Recommendation rank={ix + 1} user={rec[0]} evalScore={rec[1]} startTransition={startTransition} setTransitionState={setTransitionState} setPath={setPath} path={path} medium={medium} key={ix}></Recommendation>);
    }));
  }, [recommendations, setTransitionState, setPath, path, currentRecIX, startTransition, setCurrentRecIX, medium]);





  return (<div className={`${styles["flex"]} ${styles["flex-col"]} ${styles["justify-center"]} ${styles["items-center"]} ${styles["mt-10"]} ${styles["md:pt-4"]} ${styles["pt-8"]} ${styles["h-full"]}`}>
    
    <h1 className={`${styles["font-bold"]} ${styles["text-6xl"]} ${styles["mb-2"]} ${styles["bg-gradient-to-br"]} ${styles["from-primary"]} ${styles["to-secondary"]} ${styles["text-transparent"]} ${styles["bg-clip-text"]}`}>Badavas.</h1>

    <h2 className={`${styles["font-bold"]} ${styles["text-xl"]} ${styles["mb-4"]} ${styles["text-center"]}`}>AI Partnership Recommendations.</h2>

    <div className={`${styles["flex"]} ${styles["flex-col"]} ${styles["justify-start"]} ${styles["md:gap-4"]} ${styles["w-full"]} ${styles["box-border"]} ${styles["items-center"]} ${styles["relative"]} ${styles["h-full"]} ${styles["py-4"]}`}>

    {
      recommendationsObjects.length
      ? (recommendationsObjects)
      : (<div className={`${styles["absolute"]} ${styles["font-bold"]} ${styles["text-primary"]} ${styles["text-5xl"]} ${styles["left-1/2"]} ${styles["top-1/3"]} ${styles["-translate-x-1/2"]} ${styles["-translate-y-1/2"]}`}>
          <LoadingIcon></LoadingIcon>
        </div>)
    }

    </div>
  </div>);
};

const Recommendation = ({ rank, user, evalScore, startTransition, setTransitionState, setPath, path, medium }: { rank: number, user: User, evalScore: number, startTransition: ReactTransitionStarter, setTransitionState: OnEventSetter<TransitionState>, setPath: OnEventSetter<string>, path: string, medium: boolean }) => {
  const rankToColors = useMemo(() => { return ["", "gold", "silver", "bronze"] }, []);

  const rankSpecificBGImage = useMemo(() => { return `linear-gradient(to top right, var(--r-${rankToColors[rank]}-1), var(--r-${rankToColors[rank]}-2))`; }, [rank, rankToColors]);

  const [revealed, setRevealed] = useState(false);

  const mainInfoContainerRef = useRef<HTMLDivElement>(null);
  const DEFAULT_HEIGHT = window.innerHeight;
  const DEFAULT_WIDTH = window.innerHeight;
  const [size, setSize] = useState({ width: DEFAULT_WIDTH, height: DEFAULT_HEIGHT });

  const onResize = useCallback(() => {
    setSize({
      width: mainInfoContainerRef.current?.scrollWidth ?? DEFAULT_WIDTH,
      height: mainInfoContainerRef.current?.scrollHeight ?? DEFAULT_HEIGHT
    });
  }, [DEFAULT_WIDTH, DEFAULT_HEIGHT]);

  useEffect(() => {
    onResize();

    window.addEventListener("resize", onResize);

    return (() => {
      window.removeEventListener("resize", onResize);
    });
  }, [DEFAULT_WIDTH, DEFAULT_HEIGHT, onResize]);



  return (
    <div className={`${styles["hover:scale-105"]} ${styles["relative"]} ${styles["w-min"]} ${styles["transition-transform"]} ${styles["duration-300"]}`}>

      <div ref={mainInfoContainerRef} className={`${styles["absolute"]} ${styles["max-w-2xl"]} ${styles["idle-zoom"]} ${styles["flex"]} ${styles["rounded-lg"]} ${styles["mx-auto"]} ${styles["flex-col"]} ${styles["text-black"]} ${styles["p-6"]} ${styles["bg-neutral"]} ${styles["drop-shadow-lg"]} ${revealed ? "" : styles["-z-10"]}`} style={{ width: "85vw", animationDelay: `${2000 * (rank - 1)}ms`, backgroundImage: rankSpecificBGImage }}>
        <span className={`${styles["font-bold"]} ${styles["md:text-left"]} ${styles["text-center"]} ${styles["text-xl"]} ${styles["pb-4"]}`}>Rank {rank}</span>

        <div className={`${styles["flex"]} ${styles["md:flex-row"]} ${styles["flex-col"]} ${styles["gap-4"]} ${styles["md:gap-12"]}`}>

          <div className={`${styles["w-full"]} ${styles["md:w-min"]} ${styles["flex-1"]} ${styles["flex"]} ${styles["items-center"]} ${styles["justify-center"]}`}>
            <PieChart className={`${styles["!text-black"]}`} percent={evalScore} thickness={medium ? "2rem" : "1.5rem"} size={medium ? "16rem" : "10rem"}></PieChart>
          </div>

          <div className={`${styles["flex"]} ${styles["flex-col"]} ${styles["relative"]} ${styles["gap-4"]} ${styles["overflow-clip"]}`} style={medium ? { width: `calc(100% - 20rem)` } : {}}>

            <MiniProfile user={user} startTransition={startTransition} setTransitionState={setTransitionState} setPath={setPath} path={path} className={`${styles["max-w-full"]}`} innerClassName={`${styles["!px-0"]} ${styles["!text-black"]} ${styles["bg-transparent"]}`}></MiniProfile>

            <div className={`${styles["text-wrap"]} ${styles["break-words"]} ${styles["w-full"]} ${styles["pl-2"]} ${styles["mt-2"]}`}>
              <span className={`${styles["font-bold"]} ${styles["w-full"]}`}>Description:</span><br />
              <p className={`${styles["overflow-y-scroll"]} ${styles["pr-2"]}`} style={{ height: "15dvh" }}>{user.description}</p>
            </div>

          </div>

        </div>
      </div>
      
      <Animated animationIn="fadeIn" animationOut="rotateOutUpLeft" isVisible={!revealed} animationInDuration={0} className={`${styles["z-10"]}`}>
        <div>
          <div className={`${styles["flex"]} ${styles["rounded-lg"]} ${styles["items-center"]} ${styles["justify-center"]} ${styles["left-0"]} ${styles["right-0"]} ${styles["cursor-pointer"]} ${styles["top-0"]} ${styles["bottom-0"]} ${styles["mb-8"]}`} style={{ backgroundImage: rankSpecificBGImage, width: `${size.width}px`, height: `${size.height}px` }} onClick={() => { setRevealed(true); }}>
            <span className={`${styles["font-semibold"]} ${styles["text-8xl"]} ${styles["mix-blend-overlay"]} ${styles["text-neutral-content"]}`}>{rank}</span>
          </div>
        </div>
      </Animated>

    </div>
  );
};
