import { User } from "../../../../lib";
import { OnEventSetter, ReactTransitionStarter, TransitionState } from "../../types";
import styles from "../../css/dist/index.module.css";
import { CSSProperties, useEffect, useState } from "react";
import { getIsSupporter, getPFP } from "../../connections";
import { Link } from "./link";
import { BlurTextSetup } from "./gradienttext";

export const MiniProfile = ({ pfpURL, noSupporterDisplay, user, startTransition, noRedirect, setTransitionState, setPath, path, className, ping, link, onClick, loadImage, innerClassName, noPFP, style, innerStyle }: { noRedirect?: boolean, pfpURL?: string, user: User, startTransition: ReactTransitionStarter, setTransitionState: OnEventSetter<TransitionState>, setPath: OnEventSetter<string>, path: string, className?: string, ping?: boolean, link?: string, onClick?: () => void, loadImage?: boolean, innerClassName?: string, noPFP?: boolean, style?: CSSProperties, innerStyle?: CSSProperties, noSupporterDisplay?: boolean }) => {
  const [pfpURL_, setPFPURL_] = useState("");
  const [isSupporter, setIsSupporter] = useState(false);

  useEffect(() => {
    if (noSupporterDisplay) return;
    getIsSupporter(user.uid).then((_isSupporter) => {
      setIsSupporter(_isSupporter ?? false);
    });
  }, [user.uid, noSupporterDisplay]);

  useEffect(() => {
    // noPFP !== loadImage
    // loadImage keeps the pfp area but just does not fetch the image
    // noPFP both removes the pfp area and does not fetch the image
    if (noPFP) return;
    if (loadImage === false) return;
    if (pfpURL) {
      setPFPURL_(pfpURL);
      return;
    }
    getPFP(user.uid).then((pfpBlob) => {
      setPFPURL_(URL.createObjectURL(pfpBlob));
    });
  }, [loadImage, user.uid, pfpURL, noPFP]);

  return (<Link noRedirect={noRedirect} startTransition={startTransition} redirectPath={link === undefined ? `/profile/${user.uid}` : link} setTransitionState={setTransitionState} setPath={setPath} path={path} transition={"maskRL"} className={`${className} ${styles["max-w-72"]}`} onClick={onClick} style={style}>

    <div className={`${styles["flex"]} ${styles["bg-neutral"]} ${styles["text-neutral-content"]} ${styles["rounded-xl"]} ${styles["py-3"]} ${styles["px-6"]} ${styles["items-center"]} ${innerClassName}`} style={innerStyle}>
      <div className={`${styles["flex"]} ${styles["flex-1"]} ${styles["overflow-x-hidden"]} ${styles["gap-4"]}`}>

        {/* PFP DISPLAY */}
        {
          noPFP === true
          ? null
          : (<div className={`${styles["avatar"]}`}>
              <div className={`${styles["h-12"]} ${styles["rounded"]}`}>
                {/* eslint-disable-next-line */}
                <img className={`${styles["aspect-square"]}`} alt="profile picture" src={pfpURL_} />
              </div>
            </div>)
        }

        {/* USERNAME AND UID DISPLAY */}
        <div className={`${styles["flex"]} ${styles["flex-col"]} ${styles["overflow-hidden"]} ${styles["flex-1"]}`}>
          <span className={`${styles["font-bold"]} ${styles["overflow-ellipsis"]} ${styles["overflow-hidden"]} ${styles["text-nowrap"]}`}>{user.username}</span>
          <span className={`${styles["overflow-ellipsis"]} ${styles["overflow-hidden"]} ${styles["text-nowrap"]}`}>@{user.uid}</span>
        </div>

      </div>

      {
        isSupporter && !noSupporterDisplay
        ? (<div>
            <BlurTextSetup>
              <span className={`${styles["font-bold"]} ${styles["font-inter"]} ${styles["ml-6"]} ${styles["text-5xl"]} ${styles["text-primary"]}`}>B.</span>
            </BlurTextSetup>
          </div>)
        : null
      }

      {
        ping
        ? (
            <div className={`${styles["text-accent"]}`}>
              <div className={`${styles["animate-ping"]} ${styles["text-lg"]} ${styles["absolute"]}`}>⬤</div>
              <div className={`${styles["text-lg"]}`}>⬤</div>
            </div>
          )
        : null
      }
    </div>

  </Link>);
};
