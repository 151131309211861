import { JSXElements } from "../../types";
import styles from "../../css/dist/index.module.css";

export const Modal = ({ children, id, onOpen }: { children: JSXElements, id: string, onOpen?: () => void }) => {
  return (<>
    <input type="checkbox" id={id} className={`${styles["modal-toggle"]}`} onClick={onOpen}  />
    <div className={`${styles.modal} ${styles["z-10"]}`}>
      <div className={`${styles["bg-neutral"]} ${styles["text-neutral-content"]} ${styles["modal-box"]} ${styles.relative} ${styles["max-h-96"]} ${styles["overflow-y-scroll"]}`}>
        <label htmlFor={id} id={`${id}-close`} className={`${styles.btn} ${styles["btn-sm"]} ${styles["btn-circle"]} ${styles["border-none"]} ${styles["font-bold"]} ${styles["absolute"]} ${styles["right-2"]} ${styles["top-2"]}`}>✕</label>
        <div className={`${styles["form-control"]} ${styles["max-h-96"]} ${styles["mt-4"]}`}>
          {children}
        </div>
      </div>
    </div>
  </>);
}