import { CSSProperties, useCallback, useEffect, useState } from "react";
import { JSXElements, OnEventSetter, Transition, TransitionState } from "../../types";
import { redirect } from "../App";

export const Link = ({ className, noRedirect, startTransition, redirectPath, setTransitionState, setPath, path, transition, children, style, ifCurrentClasses, ifNotCurrentClasses, onClick, matchOnlyURLStart }: {
  className?: string,
  startTransition: React.TransitionStartFunction,
  redirectPath: string,
  setTransitionState: OnEventSetter<TransitionState>,
  setPath: OnEventSetter<string>,
  path: string,
  transition: Transition,
  noRedirect?: boolean,
  ifCurrentClasses?: string,
  ifNotCurrentClasses?: string,
  children: JSXElements | string,
  style?: CSSProperties,
  onClick?: () => void,
  matchOnlyURLStart?: boolean
}) => {
  const [current, setCurrent] = useState(false);

  useEffect(() => {
    if (matchOnlyURLStart) {
      setCurrent(window.location.pathname.split("/")[1] === redirectPath.split("/")[1]);
      return;
    }
    setCurrent(window.location.pathname === redirectPath);
    // eslint-disable-next-line
  }, [window.location.pathname, redirectPath]);

  const onClickCallback = useCallback((evt: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    if (onClick) onClick();

    if (noRedirect === true) return;

    if (current) evt.preventDefault();
    else if (evt.button === 0) {
      evt.preventDefault();
      
      redirect(startTransition, redirectPath, transition, transition, setTransitionState, setPath);
    }
  }, [current, onClick, redirectPath, setPath, setTransitionState, startTransition, transition, noRedirect]);

  // W not repetitive code totally
  return noRedirect ? (<span
      className={`${current ? ifCurrentClasses : ifNotCurrentClasses} ${className}`}
      onClick={onClickCallback}
      style={style}
    >
      { children }
    </span>
  ) : (<a
    className={`${current ? ifCurrentClasses : ifNotCurrentClasses} ${className}`}
    href={redirectPath}
    onClick={onClickCallback}
    style={style}
  >
    { children }
  </a>);
};
