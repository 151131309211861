import styles from "../../css/dist/index.module.css";
import { JSXElements, MultipleJSXElements } from "../../types";

/** tabNames length must equal the length of children */
export const TabList = ({ tabNames, children, id, className }: { tabNames: string[], children: MultipleJSXElements, id: string, className?: string }) => {
  return (<div role="tablist" className={`${styles["tabs"]} ${styles["tabs-lifted"]} ${styles["w-full"]} ${className}`}>
    {
      children.map((child, ix) => {
        return (<Tab tabTitle={tabNames[ix]} listID={id} key={ix} defaultChecked={!ix}>{ child }</Tab>);
      })
    }
  </div>);
};

const Tab = ({ children, tabTitle, listID, defaultChecked }: { children: JSXElements, tabTitle: string, listID: string, defaultChecked?: boolean }) => {
  return (<>
    <input type="radio" name={listID} role="tab" className={`${styles["tab"]}`} aria-label={tabTitle} defaultChecked={defaultChecked} />
    <div role="tabpanel" className={`${styles["tab-content"]} ${styles["bg-base-100"]}} ${styles["rounded-lg"]} ${styles["p-6"]} ${styles["border-base-300"]} ${styles["w-full"]} ${styles["overflow-scroll"]}`}>
      { children }
    </div>
  </>);
};
