import { useCallback, useEffect, useRef, useState } from "react";
import styles from "../../css/dist/index.module.css";
import { JSXElements, OnEventSetter, ReactTransitionStarter, TransitionState } from "../../types";
import { User } from "../../../../lib";
import { deletePitch, getIsSupporter, getPFP, getPitch, getPitchCount, getPitchThumbnail, getPitchViews, getSimilarity, getUser, sendVerifEmail, updateUser, UserEditableProperty } from "../../connections";
import { Animated } from "react-animated-css";
import { TabList } from "../util/tabs";
import { Link } from "../util/link";
import { ChatIcon } from "../svg/svgs";
import { Modal } from "../util/modal";
import { redirect } from "../App";
import { ErrorNotif } from "../util/errornotif";
import { FILE_VALIDITY_CHECKS, VALIDITY_CHECKS } from "../../validity-checks";
import { PieChart } from "../util/piechart";
import numeral from "numeral";
import { LoadingIcon } from "../util/loadingicon";

export const Profile = ({ currUser, startTransition, setTransitionState, setPath, path }: { currUser: User, startTransition: ReactTransitionStarter, setTransitionState: OnEventSetter<TransitionState>, setPath: OnEventSetter<string>, path: string }) => {
  const [otherUser, setOtherUser] = useState<User | undefined>();
  const [isSupporter, setIsSupporter] = useState(false);
  useEffect(() => {
    const pathSplit = path.split("/");
    if (pathSplit.length !== 3) {
      setOtherUser(undefined);
      return;
    }
    const uid = pathSplit[2];
    getUser(uid).then((user) => {
      if (user === undefined) {
        redirect(startTransition, "/signout", "instant", "instant", setTransitionState, setPath);
        window.location.reload();
      }

      setOtherUser(user);
    }).catch(console.error);
    getIsSupporter(uid).then((_isSupporter) => {
      setIsSupporter(_isSupporter ?? false);
    }).catch(console.error);
  }, [path, setPath, setTransitionState, startTransition]);

  const [pfpURL, setPfpURL] = useState("");
  useEffect(() => {
    if (!otherUser) return;
    getPFP(otherUser.uid).then((pfpBlob) => {
      setPfpURL(URL.createObjectURL(pfpBlob));
    }).catch(console.error);
  }, [otherUser]);

  const [editable, setEditable] = useState(false);
  useEffect(() => {
    if (!otherUser) return;
    setEditable(currUser.uid === otherUser.uid);
  }, [currUser, otherUser]);

  const [userPitchThumbnailURLs, setUserPitchThumbnailURLs] = useState<string[]>([]);
  const [userPitchViewCounts, setUserPitchViewCounts] = useState<number[]>([]);
  useEffect(() => {
    if (!otherUser) return;
    getPitchCount((otherUser.uid)).then(async (pitchCnt) => {
      let availPitchIXs = [];
      for (let pitchIX=0;pitchIX<pitchCnt;pitchIX++) {
        availPitchIXs.push(pitchIX);
      }
      try {
        const [pitchThumbnailURLs, pitchViewCounts] = await Promise.all([
          await Promise.all(availPitchIXs.map(async (pitchIX) => {
            const pitchTMBBlob = await getPitchThumbnail(otherUser.uid, pitchIX);
            return URL.createObjectURL(pitchTMBBlob);
          })),
          await Promise.all(availPitchIXs.map(async (pitchIX) => {
            const pitchViewCount = await getPitchViews(otherUser.uid, pitchIX);
            return pitchViewCount ?? 0;
          })),
        ]);
        setUserPitchThumbnailURLs(pitchThumbnailURLs);
        setUserPitchViewCounts(pitchViewCounts);
      } catch (e) {
        console.error(e);
      }
    }).catch(console.error);
  }, [otherUser]);

  const DEFAULT_SUPPORTER_BANNER_URL = require("../../utility/super-supporters/default-banner.png");

  const [profileEditInputs, setProfileEditInputs] = useState<JSXElements>(null);
  const [profileEditTitle, setProfileEditTitle] = useState("");
  const [profileEditSubtitle, setProfileEditSubtitle] = useState("");
  const [componentBeingEdited, setComponentBeingEdited] = useState<UserEditableProperty | undefined>();
  const editProfileModalOpenerRef = useRef<HTMLLabelElement>(null);
  const [inputtedText, setInputtedText] = useState("");
  const onEditRequest = useCallback((component: UserEditableProperty, componentDisplayName: string, subtitle: string, placeholderText: string, inputType: "textarea" | "textinput" | "fileinput", currentValue: string) => {
    if (!editable) return;

    setComponentBeingEdited(component);
    setProfileEditTitle(`Editing ${componentDisplayName}`);
    setProfileEditSubtitle(subtitle);

    switch (inputType) {
      case "textarea":
        setProfileEditInputs(<textarea key={`edit-${componentDisplayName}`} className={`${styles["textarea"]} ${styles["w-full"]} ${styles["h-40"]}`} placeholder={placeholderText} defaultValue={currentValue} onInput={(evt: React.FormEvent) => {
          if (!(evt.target instanceof HTMLTextAreaElement)) return;
          setInputtedText(evt.target.value);
        }}></textarea>);
        break;
      case "textinput":
        setProfileEditInputs(<input key={`edit-${componentDisplayName}`} defaultValue={currentValue} type="text" className={`${styles["input"]} ${styles["w-full"]}`} placeholder={placeholderText} onInput={(evt: React.FormEvent) => {
          if (!(evt.target instanceof HTMLInputElement)) return;
          setInputtedText(evt.target.value);
        }}></input>);
        break;  // for good habits
    }

    editProfileModalOpenerRef.current?.click();
  }, [editable]);

  const editFileModalOpenerRef = useRef<HTMLLabelElement>(null);
  const [editorFileInputID, setEditorFileInputID] = useState("");
  const [acceptedFileUploadTypes, setAcceptedFileUploadTypes] = useState("");
  const [inputtedFile, setInputtedFile] = useState<File | undefined>();
  const [fileEditAction, setFileEditAction] = useState("");
  type EditableProfileFiles = "pfp";
  const [fileComponentBeingEdited, setFileComponentBeingEdited] = useState<EditableProfileFiles>();
  const onFileEditRequest = useCallback((component: EditableProfileFiles, componentDisplayName: string, subtitle: string, formID: string, action: string, acceptedTypes: string) => {
    if (!editable) return;
    
    setFileEditAction(action);
    setProfileEditTitle(componentDisplayName);
    setProfileEditSubtitle(subtitle);
    setFileComponentBeingEdited(component);
    setEditorFileInputID(formID);
    setAcceptedFileUploadTypes(acceptedTypes);
    
    editFileModalOpenerRef.current?.click();
  }, [editable]);

  const [valid, setValid] = useState(false);
  const failAttempt = useCallback(() => {
    setValid(false);
    setAttemptingEdit(false);
  }, []);

  const [attemptingEdit, setAttemptingEdit] = useState(false);
  const onFileEditAttempt = useCallback(async (evt: React.MouseEvent) => {
    if (attemptingEdit) {
      evt.preventDefault();
      failAttempt();
      return;
    }

    if (!fileComponentBeingEdited) {
      evt.preventDefault();
      failAttempt();
      setErrorText("Something went wrong... Please try again.");
      return;
    }

    const errorResult = await FILE_VALIDITY_CHECKS[fileComponentBeingEdited](inputtedFile, acceptedFileUploadTypes);
    if (errorResult) {
      evt.preventDefault();
      failAttempt();
      setErrorText(errorResult);
      return;
    }

    setAttemptingEdit(true);
  }, [acceptedFileUploadTypes, attemptingEdit, failAttempt, fileComponentBeingEdited, inputtedFile]);
  const onEditAttempt = useCallback(async () => {
    if (attemptingEdit) {
      failAttempt();
      return;
    }

    if (!componentBeingEdited) {
      failAttempt();
      setErrorText("Something went wrong... Please try again.");
      return;
    }

    if (componentBeingEdited === "password") return;

    const errorResult = await VALIDITY_CHECKS[componentBeingEdited](
      inputtedText
    );
    if (errorResult) {
      failAttempt();
      setErrorText(errorResult);
      return;
    }

    setAttemptingEdit(true);

    const success = await updateUser(componentBeingEdited, inputtedText.trim());
    if (!success) {
      setErrorText("Something unexpected happened. Please try again.");
      return;
    }

    if (componentBeingEdited === "uid") {
      redirect(startTransition, "/signout", "maskBT", "maskBT", setTransitionState, setPath);
    } else {
      if (componentBeingEdited === "email") {
        await sendVerifEmail(currUser.uid);
      }
      window.location.reload();
    }

    setAttemptingEdit(false);
  }, [attemptingEdit, componentBeingEdited, currUser.uid, inputtedText, setPath, setTransitionState, startTransition, failAttempt]);

  const [errorText, setErrorText] = useState<string | undefined>();


  const [evalScore, setEvalScore] = useState(0);
  const [simScoreJustification, setSimScoreJustification] = useState("");
  useEffect(() => {
    if (!otherUser) return;

    getSimilarity(currUser, otherUser).then((simData) => {
      if (!simData) return;
      const { score: simScore, justification: _simScoreJustification} = simData;
      setEvalScore(simScore);
      setSimScoreJustification(_simScoreJustification);
    }).catch(console.error);
  }, [currUser, otherUser]);


  const deletePitchModalOpenerRef = useRef<HTMLLabelElement>(null);
  const [pitchConsiderDeleteVidURL, setPitchConsiderDeleteVidURL] = useState<string>();
  const [pitchConsiderDeleteIX, setPitchConsiderDeleteIX] = useState(0);
  const [attemptingPitchDeletion, setAttemptingPitchDeletion] = useState(false);

  const requestDeletePitch = useCallback((ix: number) => {
    if (!otherUser) return;

    setPitchConsiderDeleteIX(ix);  // this first bc used by deletion process and want to be accurate... not exactly guarantee accuracy by putting this first though...

    getPitch(otherUser.uid, ix).then((pitchBlob) => {
      setPitchConsiderDeleteVidURL(URL.createObjectURL(pitchBlob));
    });  // starttransition in case of long load here? not render so idt it will work... cld read abt this, but not important rn

    deletePitchModalOpenerRef.current?.click();
  }, [otherUser]);
  const attemptDeletePitch = useCallback(async () => {
    if (attemptingPitchDeletion) return;

    setAttemptingPitchDeletion(true);

    await deletePitch(pitchConsiderDeleteIX);

    window.location.reload();
  }, [pitchConsiderDeleteIX, attemptingPitchDeletion]);


  const [updatingAvailable, setUpdatingAvailable] = useState(false);
  const updateAvailable = useCallback(async () => {
    if (updatingAvailable || !otherUser) return;

    setUpdatingAvailable(true);

    const success = await updateUser("avail", otherUser.avail ? 0 : 1);

    if (!success) {
      setErrorText("Something unexpected happened. Please try again.");
      return;
    }

    window.location.reload();
  }, [otherUser, updatingAvailable]);


  return (<div className={`${styles["p-4"]} ${styles["w-full"]} ${styles["max-w-6xl"]} ${styles["mx-auto"]}`}>
      <Animated animationIn="fadeInUp" animationOut="fadeOutDown" animationOutDelay={1000} isVisible={otherUser !== undefined}>
      {
        otherUser
        ? (<div className={`${styles["flex"]} ${styles["flex-col"]} ${styles["gap-4"]} ${styles["items-center"]} ${styles["relative"]} ${styles["mb-4"]}`}>
            {
              isSupporter
              ? (<div className={`${styles["w-full"]} ${styles["-mb-6"]} ${editable ? styles["cursor-pointer"] :""}`} onClick={() => {
                  onEditRequest("supporter_banner_url", "Banner", `Your Super Supporter Banner URL must end in .png, .jpeg, .jpg, or .gif.`, "New Banner URL", "textinput", otherUser.supporter_banner_url??"");
                }}>
                  <img className={`${styles["w-full"]} ${styles["rounded"]} ${styles["border-base-300"]} ${styles["border"]}`} style={{ aspectRatio: "8 / 1" }} src={otherUser.supporter_banner_url ?? DEFAULT_SUPPORTER_BANNER_URL} alt="avatar"></img>
                </div>)
              : null
            }
            <div className={`${styles["avatar"]} ${styles["drop-shadow-md"]} ${editable ? styles["cursor-pointer"] :""}`}>
              <div className={`${styles["h-24"]} ${styles["rounded"]}`}>
                {
                  pfpURL
                  ? (
                      // eslint-disable-next-line
                      <img className={`${styles["aspect-square"]}`} alt="profile picture" src={pfpURL} onClick={() => {
                        onFileEditRequest("pfp", "Profile Picture", `Max file size: 100 KB. Recommended resolution: 200x200.`, "pfp-input", "/api/upload-pfp", `image/png, image/jpeg${isSupporter ? ", image/gif" : ""}`);
                      }} />
                    )
                  : null
                }
              </div>
            </div>

            <div className={`${styles["text-center"]} ${styles["w-full"]}`}>
              {
                isSupporter
                ? (
                    <label htmlFor="supersupporter-desc-modal">
                      <span className={`${styles["italic"]} ${styles["w-full"]} ${styles["text-primary"]} ${styles["opacity-90"]} ${styles["block"]} ${styles["cursor-pointer"]} ${styles["overflow-ellipsis"]} ${styles["overflow-hidden"]}`}>Badavas Super Supporter</span>
                    </label>
                  )
                : null
              }

              {
                <span onClick={editable ? updateAvailable : () => {}} className={`${styles["italic"]} ${styles["w-full"]} ${styles["text-info"]} ${otherUser.avail ? `${styles["opacity-90"]}` : `${styles["opacity-70"]}`} ${styles["block"]} ${editable ? styles["cursor-pointer"] : ""} ${styles["overflow-ellipsis"]} ${styles["overflow-hidden"]}`}>{otherUser.avail ? "Available for Partnership" : "Taken, Unavailable to Partner"}</span>
              }

              <h1 className={`${styles["font-bold"]} ${styles["w-full"]} ${styles["text-3xl"]} ${styles["overflow-ellipsis"]} ${styles["overflow-hidden"]} ${editable ? styles["cursor-pointer"] :""}`} onClick={() => {
                onEditRequest("username", "Username", ``, "New Username", "textinput", otherUser.username);
              }}>{otherUser.username}</h1>

              <h3 className={`${styles["italic"]} ${styles["w-full"]} ${styles["opacity-70"]} ${editable ? styles["cursor-pointer"] :""} ${styles["overflow-ellipsis"]} ${styles["overflow-hidden"]}`} onClick={() => {
                onEditRequest("uid", "User ID", ``, "New User ID", "textinput", otherUser.uid);
              }}>@{otherUser.uid}</h3>
            </div>

            <div className={`${styles["text-center"]} ${styles["w-full"]}`}>

              <Link startTransition={startTransition} redirectPath={`/verify`} setTransitionState={setTransitionState} setPath={setPath} path={path} transition={"maskRL"} className={`${styles["font-semibold"]} ${styles["w-full"]} ${styles["overflow-ellipsis"]} ${styles["overflow-hidden"]}`}>{otherUser.verified ? `✅ Verification Level ${otherUser.verified}` : "❌ Unverified"}</Link>
              <br/>
              
              <label htmlFor="cscore-desc-modal"><span className={`${styles["font-semibold"]} ${styles["cursor-pointer"]} ${styles["overflow-ellipsis"]} ${styles["overflow-hidden"]}`}>⭐ CScore:</span> {otherUser.cscore}</label>
              <br/>

              📧 <span className={`${styles["opacity-70"]} ${styles["w-full"]} ${styles["overflow-ellipsis"]} ${styles["overflow-hidden"]} ${styles["cursor-pointer"]}`} onClick={() => {
                onEditRequest("email", "Email", ``, "New Email", "textinput", otherUser.email);
              }}>{editable ? otherUser.email : <a href={`mailto:[${otherUser.email}]`}>{otherUser.email}</a>}</span>
            </div>

            {
              !editable
              ? (<div className={`${styles["join"]} ${styles["join-vertical"]} ${styles["border"]} ${styles["rounded-lg"]} ${styles["border-base-300"]} ${styles["items-center"]} ${styles["gap-4"]} ${styles["text-center"]} ${styles["w-full"]}`}>
                  <div className={`${styles["border-b"]} ${styles["border-b-base-300"]} ${styles["w-full"]} ${styles["text-center"]} ${styles["p-2"]}`}>AI Similarity Score</div>
                  {
                    simScoreJustification  // cannot base off evalScore bc evalScore can equal 0, even when functioning correctly
                    ? (<div className={`${styles["py-4"]} ${styles["flex"]} ${styles["flex-col"]} ${styles["items-center"]} ${styles["gap-4"]}`}>
                        <PieChart percent={evalScore} thickness={"1.5rem"} size={"10rem"}></PieChart>
                        <p className={`${styles["break-words"]} ${styles["px-6"]} ${styles["py-2"]}`}>{simScoreJustification}</p>
                      </div>)
                    : <LoadingIcon></LoadingIcon>
                  }
                </div>)
              : null
            }

            {
              <TabList tabNames={["Description", "Wants"]} id="profile-info-tabs" className={`${styles["mt-4"]}`}>
                <div className={`${styles["w-full"]}`}>
                  <p className={`${styles["break-words"]} ${editable ? styles["cursor-pointer"] :""}`} onClick={() => {
                    onEditRequest("description", "Description", `Describe your brand. For best results, include your location (town, province, state), size, product, industry, and specialties.`, "Your Description (e.g. Family-run boba shop located in New York City, New York. Selling online within the United States and locally within New York City).", "textarea", otherUser.description);
                  }}>
                    {otherUser.description}
                  </p>
                </div>
                <div className={`${styles["w-full"]}`}>
                  <p className={`${styles["break-words"]} ${editable ? styles["cursor-pointer"] : ""}`} onClick={() => {
                    onEditRequest("wants", "Wants", `Describe your ideal partner, and Badavas will find your pick. Try to the specify your ideal partner's location (town, province, state), size, product, industry, and specialty.`, "Your Wants (e.g. A fresh produce provider based in or near Westchester County, New York. Preferred products are fresh tomatos, fresh spinach, and fresh mushrooms).", "textarea", otherUser.wants);
                  }}>
                    {otherUser.wants}
                  </p>
                </div>
              </TabList>
            }

            {
              !editable
              ? (<div className={`${styles["w-full"]}`}>
                  <Link startTransition={startTransition} redirectPath={`/chat/${otherUser.uid}`} setTransitionState={setTransitionState} setPath={setPath} path={path} transition={"maskRL"} className={`${styles["btn"]} ${styles["btn-primary"]} ${styles["overflow-hidden"]} ${styles["text-nowrap"]} ${styles["overflow-ellipsis"]} ${styles["w-full"]}`}>
                    <ChatIcon className={`${styles["w-4"]} ${styles["h-4"]}`} /> Message {otherUser.username}
                  </Link>
                </div>)
              : null
            }

            {
              <div className={`${styles["w-full"]}`}>
                <Link startTransition={startTransition} redirectPath={`/posts/${otherUser.uid}`} setTransitionState={setTransitionState} setPath={setPath} path={path} transition={"maskRL"} className={`${styles["btn"]} ${styles["btn-primary"]} ${styles["text-nowrap"]} ${styles["overflow-ellipsis"]} ${styles["w-full"]}`}>
                  View {editable ? "Your" : `${otherUser.username}'s`} Posts
                </Link>
              </div>
            }

            {
              userPitchThumbnailURLs.length
              ? (<div className={`${styles["join"]} ${styles["join-vertical"]} ${styles["w-full"]}`}>
                  <div className={`${styles["w-full"]} ${styles["rounded-t-lg"]} ${styles["border"]} ${styles["border-base-300"]} ${styles["text-nowrap"]} ${styles["overflow-ellipsis"]} ${styles["text-center"]} ${styles["p-2"]} ${styles["text-sm"]}`}>{editable ? "Your" : `${otherUser.username}'s`} Pitches</div>
                  <div className={`${styles["flex"]} ${styles["rounded-b-lg"]} ${styles["gap-6"]} ${styles["border"]} ${styles["border-base-300"]} ${styles["p-2"]} ${styles["justify-center"]}`}>
                    {
                      userPitchThumbnailURLs.map((pitchThumbnailURL, ix) => {
                        return (<div key={ix} className={`${styles["flex"]} ${styles["flex-col"]} ${styles["items-center"]} ${styles["gap-2"]}`}>
                          <Link startTransition={startTransition} redirectPath={`/network/${otherUser.uid}/${ix}`} setTransitionState={setTransitionState} setPath={setPath} path={path} transition={"maskRL"}>
                            { pitchThumbnailURL ? (<video webkit-playsinline playsInline style={{ aspectRatio: "9 / 16", backgroundImage: "linear-gradient(to right, #000, #000)" }} src={pitchThumbnailURL} className={`${styles["w-fit"]} ${styles["rounded-lg"]} ${styles["max-h-72"]}`}></video>) : null }
                          </Link>

                          <div className={`${styles["badge"]} ${styles["rounded-lg"]} ${styles["w-full"]} ${styles["badge-primary"]} ${styles["overflow-hidden"]} ${styles["text-nowrap"]} ${styles["overflow-ellipsis"]}`}>{numeral(userPitchViewCounts[ix]).format("0a")} View{userPitchViewCounts[ix] === 1 ? "" : "s"}</div>

                          {
                            editable
                            ? (<div className={`${styles["badge"]} ${styles["cursor-pointer"]} ${styles["rounded-lg"]} ${styles["w-full"]} ${styles["h-10"]} ${styles["badge-error"]}`} onClick={() => { requestDeletePitch(ix); }}>Delete</div>)
                            : null
                          }
                        </div>);
                      })
                    }
                  </div>
                </div>)
              : null
            }
          </div>)
        : null
      }
    </Animated>

    {
      <Modal id="cscore-desc-modal">
        <div className={`${styles["relative"]} ${styles["flex"]} ${styles["flex-col"]} ${styles["items-center"]} ${styles["gap-6"]} ${styles["text-center"]} ${styles["pb-6"]}`}>
          <div className={`${styles["flex"]} ${styles["flex-col"]} ${styles["gap-2"]}`}>
            <h1 className={`${styles["font-bold"]} ${styles["text-2xl"]}`}>CScore</h1>
            <h2 className={`${styles["opacity-70"]}`}>“The mysterious CScore”</h2>
          </div>
          <div className={`${styles["w-full"]} ${styles["flex"]} ${styles["justify-center"]}`}>
            Maybe you have to interact with the site and see how your CScore changes...
          </div>
        </div>
      </Modal>
    }

    {
      isSupporter
      ? (
          <Modal id="supersupporter-desc-modal">
            <div className={`${styles["relative"]} ${styles["flex"]} ${styles["flex-col"]} ${styles["items-center"]} ${styles["gap-6"]} ${styles["text-center"]} ${styles["pb-6"]}`}>
              <div className={`${styles["flex"]} ${styles["flex-col"]} ${styles["gap-2"]}`}>
                <h1 className={`${styles["font-bold"]} ${styles["text-2xl"]}`}>Super Supporters</h1>
                <h2 className={`${styles["opacity-70"]}`}>Legitamate, Distinguished Users</h2>
              </div>

              <div className={`${styles["w-full"]}`}>
                <span className={`${styles["font-bold"]}`}>I want to be a <span className={`${styles["text-primary"]}`}>super supporter</span>. How can I do this?</span><br/>
                We do not offer <span className={`${styles["text-primary"]}`}>super supporter</span> membership for a price. Instead, we observe users who are connect well others on <span className={`${styles["text-primary"]}`}>Badavas</span> and grant them this honor. If a user feels that they serve a key part of the <span className={`${styles["text-primary"]}`}>Badavas</span> community, or that they have positively contributed to <span className={`${styles["text-primary"]}`}>Badavas</span> in a significant way, they may message <Link startTransition={startTransition} redirectPath={"/profile/natnuo"} setTransitionState={setTransitionState} setPath={setPath} path={path} transition={"maskRL"} className={`${styles["link"]}`}>@natnuo</Link> on <span className={`${styles["text-primary"]}`}>Badavas</span> to inquire about this upgrade.
              </div>

              <div className={`${styles["w-full"]}`}>
                <span className={`${styles["font-bold"]}`}>What do <span className={`${styles["text-primary"]}`}>super supporters</span> obtain?</span><br/>
                We do not provide <span className={`${styles["text-primary"]}`}>super supporters</span> with an unfair advantage in post sharing and pitch uploading—they will be displayed at the same rate as other users. However, super supporters will benefit from the increased legitimacy and the weight associated with their title. In addition, they can be identifed on the site by a <span className={`${styles["text-primary"]}`}>“B.”</span> which follows their mini-profile displays.<br/><br/>
                <img src={require("../../utility/super-supporters/super-supporter-example.png")} className={`${styles["rounded-xl"]} ${styles["mx-auto"]} ${styles["my-4"]}`} alt={"Example of a super supporter's special mini-profile display"}></img>
                <span className={`${styles["font-sm"]} ${styles["opacity-70"]} ${styles["italic"]}`}>Example of a <span className={`${styles["text-primary"]}`}>Super Supporter</span>'s mini-profile display</span><br/><br/>
                Finally, super supporters are offered new customization features, including a banner on their profile page and the ability to upload “.gif” files as their profile pictures.
              </div>
            </div>
          </Modal>
        )
      : null
    }

    {
      editable
      ? (<>
          <label htmlFor="delete-pitch-modal" ref={deletePitchModalOpenerRef} className={`${styles["hidden"]}`}></label>
          <Modal id="delete-pitch-modal">
            <div className={`${styles["relative"]} ${styles["flex"]} ${styles["flex-col"]} ${styles["items-center"]} ${styles["gap-6"]} ${styles["text-center"]} ${styles["pb-6"]}`}>
              <div className={`${styles["flex"]} ${styles["flex-col"]} ${styles["gap-2"]}`}>
                <h1 className={`${styles["font-bold"]} ${styles["text-2xl"]}`}>Delete Pitch</h1>
                <h2 className={`${styles["opacity-70"]}`}>Are you sure you want to delete this pitch?</h2>
              </div>
              <div className={`${styles["w-full"]} ${styles["flex"]} ${styles["justify-center"]}`}>
                <video playsInline webkit-playsinline src={pitchConsiderDeleteVidURL ?? require("../../utility/loading.mp4")} className={`${styles["w-2/3"]} ${styles["rounded-lg"]}`} controls muted loop autoPlay style={{ backgroundImage: "linear-gradient(to right, #000, #000)", aspectRatio: "9 / 16", maxHeight: "50dvh" }}></video>
              </div>
              <div className={`${styles["w-full"]} ${styles["flex"]} ${styles["flex-col"]} ${styles["gap-2"]} ${styles["p-2"]}`}>
                <button className={`${styles["btn"]} ${styles["btn-secondary"]} ${styles["w-full"]}`} onClick={() => { document.getElementById("delete-pitch-modal-close")?.click() }}>No</button>
                <button className={`${styles["btn"]} ${styles["btn-error"]} ${styles["w-full"]}`} onClick={attemptDeletePitch}>Yes</button>
              </div>
            </div>
          </Modal>
        </>)
      : null
    }

    {
      editable
      ? (<>
          <label htmlFor="edit-profile-modal" ref={editProfileModalOpenerRef} className={`${styles["hidden"]}`}></label>
          <Modal id="edit-profile-modal">
            <div className={`${styles["relative"]} ${styles["flex"]} ${styles["flex-col"]} ${styles["items-center"]} ${styles["gap-4"]} ${styles["text-center"]} ${styles["pb-6"]}`}>
              <h1 className={`${styles["font-bold"]} ${styles["text-2xl"]}`}>{profileEditTitle}</h1>
              <h2 className={`${styles["opacity-70"]}`}>{profileEditSubtitle}</h2>
              <div className={`${styles["w-full"]}`}>{profileEditInputs}</div>
              <div className={`${styles["w-full"]} ${styles["p-2"]}`}>
                <button className={`${styles["btn"]} ${styles["btn-accent"]} ${styles["drop-shadow-accent"]} ${styles["w-full"]} ${(attemptingEdit || !valid) ? styles["disabled"] : ""}`} onClick={onEditAttempt}>Edit</button>
              </div>
            </div>
          </Modal>
        </>)
      : null
    }
    
    {
      editable
      ? (<>
          <label htmlFor="edit-profile-file-modal" ref={editFileModalOpenerRef} className={`${styles["hidden"]}`}></label>
          <Modal id="edit-profile-file-modal">
              <form
                action={fileEditAction}
                encType="multipart/form-data"
                method="post"
                className={`${styles["relative"]} ${styles["flex"]} ${styles["flex-col"]} ${styles["items-center"]} ${styles["gap-4"]} ${styles["text-center"]} ${styles["pb-6"]}`}
              >
                <h1 className={`${styles["font-bold"]} ${styles["text-2xl"]}`}>{profileEditTitle}</h1>
                <h2 className={`${styles["opacity-70"]}`}>{profileEditSubtitle}</h2>
                <div className={`${styles["w-full"]}`}>
                  <input
                    type="file"
                    id={editorFileInputID}
                    name={editorFileInputID}
                    accept={acceptedFileUploadTypes}
                    className={`${styles["file-input"]} ${styles["w-full"]}`}
                    onInput={(evt: React.FormEvent) => {
                      if (!(evt.target instanceof HTMLInputElement) || !evt.target.files?.length) {
                        setInputtedFile(undefined);
                        return;
                      }
                      setInputtedFile(evt.target.files[0]);
                    }}
                  ></input>
                </div>
                <div className={`${styles["w-full"]} ${styles["p-2"]}`}>
                  <button className={`${styles["btn"]} ${styles["btn-accent"]} ${styles["drop-shadow-accent"]} ${styles["w-full"]} ${attemptingEdit ? styles["disabled"] : ""}`}onClick={onFileEditAttempt}>Edit</button>
                </div>
              </form>
            </Modal>
          </>)
      : null
    }

    <ErrorNotif errorText={errorText} onClose={() => { setErrorText(undefined); }}></ErrorNotif>
  </div>);
};
