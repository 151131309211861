import { checkEmailValidity, checkUIDValidity } from "./connections";

const checkIllegal = (s: string, pt: string, allowExtra: boolean) => {
  const illegalSequences = ["{", "}", "Business A", "Business B", "Businesses A and B", "Businesses B and A", " 500 "];

  let foundIllegal: string[] = [];
  illegalSequences.map((vv) => {
    const rss = !s.includes(vv);
    if (!rss) foundIllegal.push(vv);
    return rss;
  });
  if (foundIllegal.length > 0) return `${pt} may not contain these sequences: ${foundIllegal.join(", ")}`;

  const matches = s.match(/[A-Za-z0-9_.\-~]+/g);
  if (matches&&!allowExtra&&matches[0]!==s) return "Illegal characters found";

  return "";
}

// NOTE: "..." AT THE END OF AN ERROR RESPONSE WILL PREVENT THE RESPONSE FROM BEING SHOWN
export const VALIDITY_CHECKS = {
  "uid": async (value: string): Promise<string> => {
    value = value.trim();
    if (!value.length) {
      return "Please enter a user ID...";
    }
    if (value.length > 30) {
      return `User ID over length limit with ${value.length}/30 characters`;
    }
    for (let i = 0; i < value.length; i++) {
      const charCode = value.charCodeAt(i);
      if (charCode > 64 && charCode < 91) {
        return "User ID letters must be lowercase.";
      } else if (
        charCode !== 45 &&
        charCode !== 95 &&
        charCode !== 151 &&
        !(charCode > 47 && charCode < 58) &&
        !(charCode > 96 && charCode < 123)
      ) {
        return "User ID characters must be lowercase alphanumeric, _, or - (no en/em dashes).";
      }
    }
    if (!(await checkUIDValidity(value.trim()))) {
      return "This user ID has been taken.";
    }
    return checkIllegal(value, "User ID", true);
  },
  "type": (value: string): string => {
    if (!value.length) {
      return "Please select a user class...";
    }
    if (!(new Set(["Individual", "Company"]).has(value))) {
      return `"${value}" is not a valid user class.`;
    }
    return "";
  },
  "username": (value: string): string => {
    value = value.trim();
    if (!value.length) {
      return "Please enter a username...";
    }
    if (value.length > 65) {
      return `Username over length limit with ${value.length}/65 characters`;
    }
    return checkIllegal(value, "Username", true);
  },
  "description": (value: string): string => {
    value = value.trim();
    if (!value.length) {
      return "Please enter a self-description...";
    }
    if (value.length < 50) {
      return `Write more about your yourself! Minimum 50 characters (currently ${value.length}) for your self-description`;
    }
    if (value.length > 256) {
      return `Self-Description over length limit with ${value.length}/256 characters`;
    }
    return checkIllegal(value, "Wants", true);
  },
  "wants": (value: string): string => {
    value = value.trim();
    if (!value.length) {
      return "Please specify what you want from a partner...";
    }
    if (value.length < 50) {
      return `Write more about your wants! Minimum 50 characters (currently ${value.length})`;
    }
    if (value.length > 256) {
      return `Ideal Partner Description: Over length limit with ${value.length}/256 characters`;
    }
    return checkIllegal(value, "Wants", true);
  },
  "email": async (value: string): Promise<string> => {
    value = value.trim();
    if (!value.length) {
      return "Please enter an email...";
    }
    if (value.length > 255) {
      return `Email over length limit with ${value.length}/255 characters`;
    }
    if (!(await checkEmailValidity(value))) {
      return "This email already has an associated account.";
    }
    const match = value.match(
      /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/g
    );
    if (!match || match[0] !== value) return "Input a valid email.";
    return "";
  },
  "supporter_banner_url": (value: string): string => {
    value = value.trim();
    if (!value.length) {
      return "Please enter a url for your Super Supporter Banner...";
    }
    if (value.length > 256) {
      return `Super Supporter Banner URL: Over length limit with ${value.length}/256 characters`
    }

    const validExtensions = new Set(["png", "jpeg", "jpg", "gif"]);

    const valSplit = value.split(".");
    if (!valSplit.length || !validExtensions.has(valSplit[valSplit.length - 1])) {
      return `Super Supporter Banner URL must end in ".png", ".jpeg", ".jpg", or ".gif"`;
    }

    return checkIllegal(value, "A Supporter's Banner URL", true);
  },
  "password": (a: string, b: string): string => {
    if (!Math.min(a.length, b.length)) {
      return "Please enter a password...";
    }
    if (Math.max(a.length, b.length) > 256) {
      return `Password over length limit with ${Math.max(a.length, b.length)}/256 characters`;
    }
    if (a !== b) {
      return "Passwords do not match";
    }
    if (a.includes("\\")) return "A password cannot contain backslashes.";
    return checkIllegal(a, "Password", true);
  },
  "avail": (value: string): string => {
    return "";
  },
};

export const FILE_VALIDITY_CHECKS = {
  "pfp": (file: File | undefined, supportedtypes: string): string => {
    if (!file) {
      return "Please choose a profile picture...";
    }

    const MAX_PFP_STORAGE_SIZE_KB = 100; const _MPSSKB = MAX_PFP_STORAGE_SIZE_KB;
    if (file.size > _MPSSKB * 1024) {  // * 1024 to reduce rounding errors (off-no chance, but still, small change and worth protection [although this typing spends more time, but helps to clarify the reason this code is better than a initially seemingly equivalent alternative])
      return `Expected file size less than ${_MPSSKB} KB for profile picture, recieved ${(file.size / 1024).toFixed(1)} KB`;
    }

    const validTypes = new Set(supportedtypes.replaceAll(" ", "").split(","));
    if (!validTypes.has(file.type)) {
      return `Expected file of type: ${supportedtypes.replaceAll("image/", "").replaceAll(" ", "").split(",")} for profile picture, got file of type: ${file.type}`;
    }

    return "";
  },
}
